const features = require('./features');

const roles = {
  ADMIN: 'admin',
  CREATOR: 'creator',
  AUDIO_ENGINEER: 'audio-engineer',
  REVIEWER: 'reviewer',
};

const permissions = {
  [roles.ADMIN]: Object.values(features),
  [roles.CREATOR]: [
    features.PLATFORM_ACCESS,
    features.ONBOARDING,
    features.UPLOAD,
    features.VIEW_CHANNEL_ANALYTICS,
    features.EDIT_CHANNEL,
    features.VIEW_TRACK_ANALYTICS,
    features.EDIT_TRACK,
    features.COACHING,
    features.LIVE_SESSION,
    features.UPLOAD_LIVE_SESSION,
    features.EDIT_LIVE_SESSION,
    features.EDIT_SERIES,
    features.VIEW_SERIES_ANALYTICS,
    features.VIDEO_CALL,
    features.EVENTS,
    features.UPLOAD_EVENTS,
    features.EDIT_EVENT,
    features.COMMUNITY,
    features.COURSES,
    features.UPLOAD_COMMUNITY,
    features.EDIT_COMMUNITY,
    features.ZOOM_AUTHORIZE,
  ],
  [roles.AUDIO_ENGINEER]: [
    features.PLATFORM_ACCESS,
    features.REVIEW,
    features.REVIEW_ALL_LOCALES,
    features.REVIEW_EDIT,
    features.REVIEW_EDIT_AUDIO,
    features.EDIT_TRACK,
    features.TRACK_REVIEW_MOVE_TO_REVIEW,
    features.SHOW_LOGOUT_OPTION,
  ],
  [roles.REVIEWER]: [
    features.PLATFORM_ACCESS,
    features.REVIEW,
    features.REVIEW_EDIT,
    features.REVIEW_EDIT_AUDIO,
    features.EDIT_TRACK,
    features.TRACK_REVIEW_MOVE,
    features.TRACK_REVIEW_MOVE_TO_REVIEW,
    features.TRACK_REVIEW_MOVE_TO_AUDIO_EDIT,
    features.TRACK_REVIEW_MOVE_TO_APPROVE,
    features.TRACK_REVIEW_DELETE,
    features.ADMIN_PAGE,
    features.VIEW_ALL_COACHES,
    features.SHOW_LOGOUT_OPTION,
  ],
};

module.exports = { roles, permissions };
