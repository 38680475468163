import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  listJournals,
  createJournal,
  deleteJournal,
  updateJournal,
} from '../../models/journal';
import { notifyHandledError } from '../../services/ErrorMonitoring';

import Analytics from '../../services/Analytics';
import Logger from '../../services/Logger';
import { resetSelectedChat } from './chats';

export const getJournals = createAsyncThunk(
  'journals/getJournals',
  async (userId) => {
    try {
      const response = await listJournals(userId);

      return response;
    } catch (error) {
      notifyHandledError(error, { message: 'Error fetching Journals' });
      return null;
    }
  }
);

export const createJournals = createAsyncThunk(
  'journals/createJournal',
  async (data) => {
    try {
      const response = await createJournal(data);
      if (response) {
        Analytics.track('Coach Journal Note Created', {
          ClientID: data && data.userId,
        });
      }

      return response.data;
    } catch (error) {
      Logger.error('Error creating journal', { error });
      return null;
    }
  }
);

export const deleteJournals = createAsyncThunk(
  'journals/deleteJournal',
  async (journalId) => {
    try {
      const response = await deleteJournal(journalId);
      if (response) {
        Analytics.track('Coach Journal Note Deleted', {
          JournalID: journalId,
        });
      }

      return response.data;
    } catch (error) {
      Logger.error('Error deleting journal', { error });
      return null;
    }
  }
);

export const updateJournals = createAsyncThunk(
  'journals/updateJournal',
  async ({ data, journalId }) => {
    try {
      const response = await updateJournal(data, journalId);

      if (response) {
        Analytics.track('Coach Journal Note Updated', {
          JournalID: journalId,
        });
        return response.data;
      }
      return null;
    } catch (error) {
      Logger.error('Error updating journal', { error });
      return null;
    }
  }
);

const initialState = {
  journals: [],
  loading: false,
  isFetched: false,
};

/* eslint-disable no-param-reassign */
export const journalsSlice = createSlice({
  name: 'journals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getJournals.pending, (state) => {
        state.loading = true;
      })

      .addCase(getJournals.fulfilled, (state, action) => {
        state.journals = action.payload;
        state.loading = false;
        state.isFetched = true;
      })
      .addCase(createJournals.fulfilled, (state, action) => {
        state.journals = [...state.journals, action.payload].sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        state.loading = false;
        state.isFetched = true;
      })
      .addCase(deleteJournals.fulfilled, (state, action) => {
        state.journals = state.journals.filter(
          (item) => item.id !== action.payload.id
        );
        state.loading = false;
        state.isFetched = true;
      })
      .addCase(updateJournals.fulfilled, (state, action) => {
        state.journals = state.journals.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
        state.loading = false;
        state.isFetched = true;
      })
      .addCase(resetSelectedChat, (state) => {
        state.journals = [];
        state.loading = false;
        state.isFetched = false;
      });
  },
});
/* eslint-disable no-param-reassign */

export default journalsSlice.reducer;
