import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getCoachingPackages } from '../../models/coaching';

export const fetchCoachingPackages = createAsyncThunk(
  'coaching/fetchCoachingPackages',
  async ({ coachId, includeInactive }, { rejectWithValue }) => {
    try {
      const coachingPackages = await getCoachingPackages(
        coachId,
        includeInactive
      );

      return coachingPackages;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

const initialState = {
  coachingPackages: null,
  isLoading: false,
  error: false,
  isFetched: false,
};

/* eslint-disable no-param-reassign */
export const coachingSlice = createSlice({
  name: 'coaching',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoachingPackages.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.coachingPackages = null;
      })

      .addCase(fetchCoachingPackages.fulfilled, (state, action) => {
        state.coachingPackages = action.payload;
        state.isLoading = false;
        state.error = false;
        state.isFetched = true;
      });
  },
});

export default coachingSlice.reducer;
