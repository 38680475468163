import axios from 'axios';

import Auth from '../services/FirebaseAuth';

import config from '../config';
import { notifyAPIError } from '../services/ErrorMonitoring';
import { featureInviteStatus } from '../utils/constants/featureInvite';

async function listFeatures({ coachId, status, feature }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/coaches/featureInvite/list`,
      data: { coachId, status, feature },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching features list' });
    return null;
  }
}

function isFeatureWaitingForReview(coachFeature) {
  if (!coachFeature) return false;
  if (!coachFeature.history || !coachFeature.history[0]) return false;
  return (
    coachFeature.history[0].status === featureInviteStatus.WAITING_FOR_REVIEW
  );
}

function isFeatureRejected(coachFeature) {
  if (!coachFeature) return false;
  if (!coachFeature.history || !coachFeature.history[0]) return false;
  return coachFeature.history[0].status === featureInviteStatus.REJECTED;
}

function isFeatureApproved(coachFeature) {
  if (!coachFeature) return false;
  if (!coachFeature.history || !coachFeature.history[0]) return false;
  return coachFeature.history[0].status === featureInviteStatus.APPROVED;
}

async function listUnapprovedFeatures() {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/coaches/featureInvite/list/unapproved`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error fetching unapproved features list',
    });
    return null;
  }
}

async function requestAccess({ coachId, status, feature }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/coaches/featureInvite`,
      data: { coachId, status, feature },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error requesting coach feature' });
    return null;
  }
}

async function updateCoachStatus({ coachId, feature, status, notes }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/coaches/featureInvite/status`,
      data: {
        coachId,
        feature,
        status,
        notes,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error updating coach status' });
    return null;
  }
}

async function retrieveCoachFeature({ coachId, feature }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/coaches/featureInvite/retrieveCoachFeature`,
      data: {
        coachId,
        feature,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error retrieving coach feature' });
    return null;
  }
}

async function deleteFeatureAccess({ coachId, feature }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/scheduling/coaches/featureInvite/${feature}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        coachId,
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error deleting feature access',
    });
    return null;
  }
}

export {
  isFeatureApproved,
  isFeatureRejected,
  isFeatureWaitingForReview,
  listFeatures,
  listUnapprovedFeatures,
  requestAccess,
  updateCoachStatus,
  retrieveCoachFeature,
  deleteFeatureAccess,
};
