import axios from 'axios';
import shuffleArray from 'shuffle-array';
import contentConstants from '../utils/constants/content';
import Logger from './Logger';
import { notifyAPIError } from './ErrorMonitoring';
import config from '../config';
import Auth from './FirebaseAuth';

async function getTracks({
  topic = null,
  coaches = null,
  contentType = 'all',
  durationFilter = contentConstants.DURATION_KEYS.ALL,
  authorName,
  authors,
  enrich = true,
  useDayBucket = false,
  channels = [],
  category = null, // Defaults to popular
  routeSuffix = null,
  limit = 10, // Configures the number of tracks returned from api.
  anyTags = null, // Optional search tags, contains at least one of them, example ['sleep', 'anxiety']
  durations = null, // Optional array of duration. If specified overrides the duration
  emotion,
  excludeTrackIds = [],
  tags = null, // Optional search tags, contains all of them, example ['sleep', 'bedtime']
  shuffle = true, // Specify if tracks are to be shuffled after fetch
  page = 0,
  excludeNoRec = true,
}) {
  let duration = contentConstants.DURATION_KEYS_FOR_REQUEST[durationFilter];
  let multipleDurations = null;

  if (durations && durations.length) {
    multipleDurations = durations.map(
      (filter) => contentConstants.DURATION_KEYS_FOR_REQUEST[filter]
    );
    duration = null;
  }

  let type = contentType;
  if (contentType === 'meditation') {
    type = 'mindfulness';
  }
  const options = {
    coaches,
    type,
    excludeTrackIds,
    authorName,
    emotion,
    duration,
    topic,
    useDayBucket,
    tags,
    enrich,
    channels,
    limit,
    anyTags,
    durations: multipleDurations,
    page,
    excludeNoRec,
  };
  if (authors && !coaches) {
    options.coaches = authors;
  }
  let apiRoute = `contents/popular`;
  const customRoutes = {
    [contentConstants.CONTENT_CATEGORY.NEW]: `contents/recent`,
    [contentConstants.CONTENT_CATEGORY.POPULAR]: `contents/popular`,
    [contentConstants.CONTENT_CATEGORY.RECOMMEND]: `contents/recommend`,
    [contentConstants.CONTENT_CATEGORY.SERIES]: `contents/series`,
    [contentConstants.CONTENT_CATEGORY.TOP]: `contents/top`,
    [contentConstants.CONTENT_CATEGORY.ALL]: `contents/list`,
  };
  if (category && customRoutes[category]) {
    apiRoute = customRoutes[category];
  }
  if (routeSuffix) {
    apiRoute = routeSuffix;
  }

  const tracks = await fetchTracks(apiRoute, options);
  if (!tracks || tracks.error) {
    Logger.warn('Error fetching tracks', { tracks });
  }
  if (shuffle && Array.isArray(tracks)) {
    return shuffleArray(tracks);
  }
  return tracks;
}

async function searchTracks({
  term = null,
  topic = null,
  contentType = 'all',
  durationFilter = contentConstants.DURATION_KEYS.ALL,
  authorName = null,
  authors = [],
  enrich = true,
  useDayBucket = false,
  channels = [],
  category = null,
  limit = 10,
  anyTags = null,
  emotion = null,
  excludeTrackIds = [],
  tags = null,
  page = 0,
}) {
  let type = contentType;
  if (contentType === 'meditation') {
    type = 'mindfulness';
  }
  const options = {
    term,
    type,
    excludeTrackIds,
    authorName,
    emotion,
    duration: contentConstants.DURATION_KEYS_FOR_REQUEST[durationFilter],
    topic,
    useDayBucket,
    tags,
    enrich,
    channels,
    authors,
    limit,
    anyTags,
    category,
    page,
  };

  const tracks = await fetchTracks('contents/search', options);
  if (!tracks || tracks.error) {
    Logger.warn('Error fetching tracks', { tracks });
    return null;
  }
  return tracks;
}

async function fetchTracks(route, options = {}) {
  const authToken = await Auth.getUserAuthToken();
  if (!route || route === '') {
    Logger.warn('No route specified');
    return null;
  }
  const url = `${config.api.auraServices}/${route}`;
  const headers = {
    Authorization: `Bearer ${authToken}`,
    Accept: `application/json`,
    'Content-Type': `application/json`,
  };

  try {
    const res = await axios({
      method: `post`,
      url,
      headers,
      data: options,
    });

    let json = res.data;

    if (typeof json === 'string') {
      try {
        json = JSON.parse(json);
      } catch (e) {
        return { error: e };
      }
    }
    const isMultiple = options.limit >= 1;

    if (Array.isArray(json) && !isMultiple) {
      [json] = json;
    }

    if (isMultiple && Array.isArray(json) && !options.enrich) {
      return json.map((x) => x.id);
    }

    return json;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching tracks' });
    return { error };
  }
}

async function listTracks({ page = 0, type = null, limit = null }) {
  const data = {
    limit,
    page,
    type,
  };

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/contents/list`,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while fetching Track list' });
    return null;
  }
}

async function tracksListCount({ type = null }) {
  const data = { type };
  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/contents/count`,
      data,
      headers: {
        'Content-Type': 'application/json',
        data,
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data.count;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while fetching Track count' });
    return null;
  }
}

const ContentServices = {
  getTracks,
  searchTracks,
  tracksListCount,
  listTracks,
};

export default ContentServices;
