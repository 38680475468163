import axios from 'axios';

import Auth from '../services/FirebaseAuth';

import config from '../config';
import { notifyAPIError } from '../services/ErrorMonitoring';

async function listCannedMessages() {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/coaching/cannedResponses`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching Journal list' });
    return null;
  }
}

async function createCannedResponse(responseItem) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/cannedResponses`,
      data: responseItem,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error adding canned response' });
    return null;
  }
}

async function deleteCannedResponse(responseItemId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/coaching/cannedResponses/${responseItemId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error deleting canned response' });
    return null;
  }
}

async function updateCannedResponseItem(updatedCannedResponse) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/coaching/cannedResponses/${updatedCannedResponse.id}`,
      data: updatedCannedResponse,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error updating canned response' });
    return null;
  }
}

export {
  listCannedMessages,
  createCannedResponse,
  deleteCannedResponse,
  updateCannedResponseItem,
};
