/**
 * @module Logger
 * @description Service for the application logging
 *    Levels configured:
 *    { crit: 1, error: 2, warn: 3, info: 4, debug: 5}
 * @example Usage example (with metadata):
 *    Logger.warn(`user ${user} not authorized`, { ip: req.ip, user: req.jwt.user});
 * @copyright Aura Health, Inc.
 */
const { v4: uuidv4 } = require('uuid');
const axios = require('axios');
const config = require('../config');
const { isClient, isProdMode } = require('../utils');

const {
  logger: { level },
  service: { name: service, version },
} = config;

/**
 * Logger
 */
const levels = {
  debug: 5,
  info: 4,
  warn: 3,
  error: 2,
  crit: 1,
};
const stackdriver = {
  debug: 100,
  info: 200,
  warn: 400,
  error: 500,
  crit: 600,
};

class Logger {
  constructor() {
    this.isServerLogger = !isClient();
    this.baseMeta = {
      loggerId: uuidv4(),
      serviceContext: {
        service,
        version,
      },
    };
    if (isProdMode() && isClient() && window.navigator) {
      const {
        appName,
        appCodeName,
        appVersion,
        platform,
        product,
        userAgent,
        vendor,
      } = window.navigator;
      this.baseMeta.browser = {
        appName,
        appCodeName,
        appVersion,
        platform,
        product,
        userAgent,
        vendor,
      };
    }
  }

  formatMeta(meta, logLevel) {
    const metaData = {
      ...this.baseMeta,
      ...meta,
      severity: stackdriver[logLevel],
    };
    metaData.timestamp = new Date().toISOString();
    return metaData;
  }

  addUserData(userData) {
    if (!userData) return;
    const { id, role, email, givenName } = userData;
    const { user = {} } = this.baseMeta;
    user.id = id;
    user.role = role;
    user.email = email;
    user.givenName = givenName;
    this.baseMeta.user = user;
  }

  addCoachData(coachData) {
    if (!coachData) return;
    const { name, tracks, approved } = coachData;
    const { user = {} } = this.baseMeta;
    user.coachName = name;
    user.coachTrackCount = tracks ? tracks.length : 0;
    user.coachApproved = approved;
    this.baseMeta.user = user;
  }

  log(message, meta, logLevel) {
    if (levels[logLevel] > levels[level]) return;
    const metaData = this.formatMeta(meta, logLevel);
    // eslint-disable-next-line no-console
    const logger = console[logLevel] || console.error;
    if (this.isServerLogger) {
      logger(JSON.stringify({ message, ...metaData }));
    } else if (!isProdMode()) {
      // No logs on client in production
      logger(message, metaData);
    }
    this.logToServer(message, metaData);
  }

  logToServer(message, meta) {
    try {
      if (isClient()) {
        axios({
          method: `post`,
          url: `${config.appDomain}/api/log`,
          timeout: 11000,
          data: { message, ...meta },
        });
      }
    } catch (err) {
      // do nothing
    }
  }

  crit(message = '', meta = {}) {
    const logMessage = message.stack || message;
    this.log(logMessage, meta, 'crit');
  }

  error(message = '', meta = {}) {
    const logMessage = message.stack || message;
    this.log(logMessage, meta, 'error');
  }

  warn(message, meta = {}) {
    this.log(message, meta, 'warn');
  }

  info(message, meta = {}) {
    this.log(message, meta, 'info');
  }

  debug(message, meta = {}) {
    this.log(message, meta, 'debug');
  }
}

module.exports = new Logger();
