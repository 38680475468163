const features = {
  COACHING: 'coaching',
  CONTENT: 'content',
  LIVE: 'live',
};

const featureInviteStatus = {
  WAITING_FOR_REVIEW: 'waitingForReview',
  REJECTED: 'rejected',
  APPROVED: 'approved',
};

module.exports = { features, featureInviteStatus };
