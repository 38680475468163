import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEventsList } from '../../models/events';
import { notifyHandledError } from '../../services/ErrorMonitoring';

export const getEventsList = createAsyncThunk(
  'events/getEventsList',
  async (data, { rejectWithValue }) => {
    try {
      const eventList = await fetchEventsList(data);

      return eventList;
    } catch (error) {
      notifyHandledError(error, { message: 'Unable get events list' });
      return rejectWithValue();
    }
  }
);

const initialState = {
  list: [],
  isLoading: false,
  isFetched: false,
  error: false,
  selectedEvent: null,
};

/* eslint-disable no-param-reassign */
export const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setSelectedEvent: (state, action) => {
      state.isLoading = false;
      state.selectedEvent = action.payload;
    },
    addNewEvent: (state, action) => {
      state.list = [action.payload, ...state.list];
    },
    updateEventList: (state, action) => {
      const index = state.list.findIndex(
        (item) => item.id === action.payload.id
      );

      const newArray = [...state.list];
      newArray.splice(index, 1, action.payload);

      newArray.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      state.list = newArray;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventsList.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.list = null;
      })

      .addCase(getEventsList.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isLoading = false;
        state.isFetched = true;
        state.error = false;
      })

      .addCase(getEventsList.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const { setSelectedEvent, updateEventList, addNewEvent } =
  eventSlice.actions;

export default eventSlice.reducer;
