import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithRedirect,
  signInWithPopup,
  browserPopupRedirectResolver,
} from 'firebase/auth';
import Logger from './Logger';
import FirebaseApp from './FirebaseApp';
import { notifyHandledError } from './ErrorMonitoring';

const { Auth: FirebaseAuth } = FirebaseApp;
const GoogleProvider = new GoogleAuthProvider();
const FacebookProvider = new FacebookAuthProvider();

async function getUserAuthToken() {
  let token = null;
  const user = FirebaseAuth.currentUser;
  try {
    if (user) {
      token = await user.getIdToken();
    }
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error getting user auth token',
      user,
    });
  }
  return token;
}

async function authSignOut() {
  Logger.info('auth: signout');
  try {
    await signOut(FirebaseAuth);
  } catch (error) {
    notifyHandledError(error, { message: 'Error logging out user' });
  }
}

const Auth = {
  instance: FirebaseAuth,
  GoogleProvider,
  FacebookProvider,
  getUserAuthToken,
  signOut: authSignOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithRedirect,
  signInWithPopup,
  browserPopupRedirectResolver,
};

export default Auth;
