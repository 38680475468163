import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getMessaging, isSupported } from 'firebase/messaging';

const config = require('../config');
const Logger = require('./Logger');

const Firebase = {
  App: null,
  Auth: null,
  Database: null,
  Messaging: null,
  Storage: null,
};

try {
  if (!Firebase.App) {
    try {
      Firebase.App = initializeApp(config.firebase);
      Firebase.Database = getDatabase(Firebase.App);
      Firebase.Auth = getAuth(Firebase.App);
      Firebase.Storage =
        typeof window !== 'undefined' && !Firebase.Storage
          ? getStorage(Firebase.App)
          : undefined;
      initializeMessaging();
    } catch (error) {
      const { code } = error || {};
      if (code === 'messaging/unsupported-browser') {
        Logger.info('Browser does not support Firebase push', { error });
      } else {
        Logger.warn('Error initializing Firebase', { error });
      }
    }
  }
} catch (err) {
  // taken from https://github.com/now-examples/next-news/blob/master/lib/db.js
  if (!/already exists/.test(err.message)) {
    Logger.error('Firebase initialization error', err);
  }
}

async function initializeMessaging() {
  const isMessagingSupported = await isSupported();
  if (isMessagingSupported) {
    Firebase.Messaging =
      typeof window !== 'undefined' ? getMessaging(Firebase.App) : undefined;
  } else {
    Logger.info('Browser does not support Firebase push');
  }
}

export default Firebase;
