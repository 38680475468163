import axios from 'axios';

import Auth from '../services/FirebaseAuth';
import config from '../config';

import { notifyAPIError } from '../services/ErrorMonitoring';

async function fetchAvailabilityList(data) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/availability/list`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      if (response.data.length === 0) return null;
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while getting availability list' });
    return null;
  }
}

export { fetchAvailabilityList };
