import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { differenceInDays } from 'date-fns';
import { fetchAvailabilityList } from '../../models/availability';
import I18NFormatter from '../../services/I18NFormatter';

const groupByDate = (availabilityList, key) => {
  const tempAvailabilityList = [...availabilityList];

  return tempAvailabilityList.reduce((previousValue, currentValue) => {
    const date = new Date(currentValue[key]);
    let formattedDate = I18NFormatter.formatDate(date, 'eee, MMM d');
    const dayDiffrence = differenceInDays(
      date.setHours(0, 0, 0, 0),
      new Date().setHours(0, 0, 0, 0)
    );

    if (dayDiffrence === 0) formattedDate = 'Today';
    else if (dayDiffrence === 1) formattedDate = 'Tomorrow';

    const dateKey = I18NFormatter.formatDate(date, 'yyyy-MM-dd');
    const newValue = previousValue;
    const dateGroup = previousValue[dateKey] || {
      formattedDate,
      items: [],
    };
    dateGroup.items.push(currentValue);
    newValue[dateKey] = dateGroup;
    return newValue;
  }, {});
};

export const getAvailabilityList = createAsyncThunk(
  'availability/getAvailabilityList',
  async (data, { rejectWithValue }) => {
    try {
      const availabilityList = await fetchAvailabilityList(data);
      const fomatedList = groupByDate(availabilityList, 'start');
      return fomatedList;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

const initialState = {
  list: null,
  isLoading: false,
  error: false,
};

/* eslint-disable no-param-reassign */
export const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAvailabilityList.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.list = null;
      })

      .addCase(getAvailabilityList.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isLoading = false;
        state.error = false;
      });
  },
});

export default availabilitySlice.reducer;
