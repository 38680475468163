/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reflections: [],
  loading: false,
  isFetched: false,
};

const reflectionsSlice = createSlice({
  name: 'reflections',
  initialState,
  reducers: {
    fetchReflectionsAction: (state) => {
      state.loading = true;
    },
    fetchedReflections: (state, action) => {
      state.reflections = action.payload;
      state.loading = false;
      state.isFetched = true;
    },
  },
});

export const { fetchReflectionsAction, fetchedReflections } =
  reflectionsSlice.actions;
export default reflectionsSlice.reducer;
