import axios from 'axios';

import FirebaseDatabase from '../services/FirebaseDatabase';
import Logger from '../services/Logger';
import Auth from '../services/FirebaseAuth';

import { getPost } from './post';
import config from '../config';

import contentConstants from '../utils/constants/content';
import Analytics from '../services/Analytics';
import {
  notifyAPIError,
  notifyHandledError,
} from '../services/ErrorMonitoring';
import { canShowNewReferral } from './coach';
import { getUrlLocale } from './locale';
import { generateExternalUrlQueryPath } from '../utils';
import referralConstants from '../utils/constants/referralConstants';

async function listMeditations(ids, { fetchStatus = false } = {}) {
  Logger.debug(`meditations: list`, { ids, fetchStatus });
  const promises = ids.map((id) => getMeditation(id, { fetchStatus }));
  return Promise.all(promises);
}

async function createMeditation(data) {
  Logger.debug('Create meditation', { data });
  const authToken = await Auth.getUserAuthToken();
  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/contents`,
    data,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };

  return axios(options).catch((error) => {
    notifyAPIError(error, { message: 'Error creating new conetnt', ...data });
    throw error;
  });
}

async function uploadTrackList(data) {
  const promises = data.tracks.map(
    (track) => !track.isExistingTrack && createMeditation(track)
  );
  return Promise.all(promises);
}

async function createSeries(data) {
  const authToken = await Auth.getUserAuthToken();
  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/series`,
    data,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };

  return axios(options).catch((error) => {
    notifyAPIError(error, { message: 'Error creating new conetnt', ...data });
    throw error;
  });
}

async function getMeditationIdFromSlug(slug) {
  const trackId = await FirebaseDatabase.getValue(`/slugMeditation/${slug}`);
  return trackId;
}

async function getMeditation(id, { fetchStatus = false } = {}) {
  try {
    let meditation = await FirebaseDatabase.getValue(`/meditations/${id}`);

    if (meditation && !meditation.deleted) {
      if (fetchStatus) {
        const { data: statusObj } = await getMeditationStatus(id);
        meditation = {
          ...meditation,
          ...(statusObj || {}),
        };
      }

      meditation.id = id;
      delete meditation.addedToIndex;
      delete meditation.micro;
      delete meditation.crawlPassed;
      delete meditation.skipCrawl;
      delete meditation.meditationFeedback;
      delete meditation.savedUsers;
      delete meditation.transcription;
      delete meditation.transcription7;
      return meditation;
    }
    return null;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching track',
      trackId: id,
    });
    return null;
  }
}

function getTrackPublicUrl(track = {}) {
  if (!track) return null;
  const { slug } = track;
  if (!isApproved(track) || !slug) return null;
  return `https://www.aurahealth.io/track/${slug}?aftp=true`;
}

function isTrackInReview(track = {}) {
  if (!track) return false;
  return !isApproved(track) && !isRejected(track);
}

function isSeriesInReview(series = {}) {
  if (!series) return false;
  return !series.approved;
}

function filterApprovedTracks(tracks) {
  if (typeof tracks.filter === 'function') {
    return tracks.filter((track) => {
      return isApproved(track);
    });
  }
  return tracks;
}

async function getUnapprovedMeditations(filters) {
  const authToken = await Auth.getUserAuthToken();

  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/contents/qa/list`,
    ...(filters ? { data: filters } : {}),
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };

  return axios(options);
}

async function getApprovedMeditations(filters) {
  const authToken = await Auth.getUserAuthToken();

  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/contents/list`,
    ...(filters ? { data: filters } : {}),
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };

  return axios(options);
}

async function getMeditationStatus(contentId) {
  const authToken = await Auth.getUserAuthToken();

  const options = {
    method: 'GET',
    url: `${config.api.auraServices}/contents/qa/status/${contentId}`,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };
  return axios(options).catch((error) => {
    notifyAPIError(error, { message: 'Error getting meditation content' });
    throw error;
  });
}

async function getMeditationPosts(id) {
  Logger.debug(`MeditationPosts: list ${id}`);

  try {
    const reviews = await FirebaseDatabase.getValue(`/reviewsIndices/${id}`);

    if (!reviews) {
      return [];
    }

    const promises = Object.keys(reviews).map((key) => getPost(key));
    return Promise.all(promises);
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching meditation posts',
      trackId: id,
    });
    return null;
  }
}

function getMeditationPhoto(meditation, size) {
  const auraTrackPic = `https://firebasestorage.googleapis.com/v0/b/firebase-auratech16.appspot.com/o/auraTrackPic.png?alt=media&token=06b53bb7-8f44-4da0-a47a-82d94c030fbb`;
  if (!meditation) {
    return auraTrackPic;
  }
  if (size && meditation.photoThumbs && meditation.photoThumbs[size]) {
    return meditation.photoThumbs[size];
  }
  if (meditation.photoUrl) {
    return meditation.photoUrl;
  }
  if (meditation.channel && meditation.channel.channelPicture) {
    return meditation.channel.channelPicture;
  }
  if (meditation.photo) {
    return meditation.photo;
  }
  return auraTrackPic;
}

function getMeditationDisplayDuration(secs) {
  const mins = Math.round(secs / 60);
  return parseInt(mins, 10);
}

function getTrackUrl(meditation, durationMode = 3) {
  if (!meditation) {
    return null;
  }

  let trackUrl = meditation.url;
  if (durationMode === 7 && meditation.url7) {
    trackUrl = meditation.url7;
  }
  return trackUrl;
}

function getSecondsDuration(meditation, durationMode = 3) {
  if (!meditation) {
    return 0;
  }

  let durationInSeconds = meditation.duration;
  if (durationMode === 7 && meditation.duration7) {
    durationInSeconds = meditation.duration7;
  }
  return durationInSeconds;
}

function getSimplifiedDuration(meditation, durationMode = 3) {
  if (!meditation) {
    return 0;
  }

  let durationInSeconds = meditation.duration;
  if (durationMode === 7 && meditation.duration7) {
    durationInSeconds = meditation.duration7;
  }
  return Math.round(durationInSeconds / 60);
}

function getChannelPhotoUrl(meditation) {
  let channelPhotoUrl = `https://firebasestorage.googleapis.com/v0/b/firebase-auratech16.appspot.com/o/auraTrackPic.png?alt=media&token=06b53bb7-8f44-4da0-a47a-82d94c030fbb`;
  if (
    meditation &&
    meditation.channel &&
    meditation.channel.channelPictureThumbs &&
    meditation.channel.channelPictureThumbs.photo200Url
  ) {
    return meditation.channel.channelPictureThumbs.photo200Url;
  }
  if (meditation && meditation.channel && meditation.channel.channelPicture) {
    channelPhotoUrl = meditation.channel.channelPicture;
  }

  return channelPhotoUrl;
}

function getChannelAuthorName(meditation) {
  let channelAuthorName = `Aura`;
  if (meditation && meditation.channel && meditation.channel.authorName) {
    channelAuthorName = meditation.channel.authorName;
  }
  return channelAuthorName;
}

function sendTrackChangesToAnalytics(newValues, oldValues) {
  if (!oldValues || !newValues) return;
  const removedTopics = Object.keys(oldValues.topics || {}).filter(
    (topic) => !newValues.topics.includes(topic)
  );

  const newTopics = newValues.topics.filter(
    (topic) => !Object.keys(oldValues.topics || {}).includes(topic)
  );

  const { id: trackId, source, channel = {} } = oldValues;
  const commonProperties = {
    'Track ID': trackId,
    Title: newValues.title,
    Coach: source,
    Channel: channel.channelName,
  };
  if (oldValues.photoUrl !== newValues.photoUrl) {
    Analytics.track('Track Image Edited', {
      ...commonProperties,
    });
  }
  if (newTopics.length > 0 || removedTopics.length > 0) {
    Analytics.track('Track Topics Edited', {
      'Topics Added': newTopics,
      'Topics Removed': removedTopics,
      ...commonProperties,
    });
  }
  if (oldValues.title !== newValues.title) {
    Analytics.track('Track Title Edited', {
      'Title Original': oldValues.title,
      'Title Edited To': newValues.title,
      ...commonProperties,
    });
  }
  if (oldValues.content !== newValues.content) {
    Analytics.track('Title Description Edited', {
      ...commonProperties,
    });
  }
}

function trackTypeDisplayStringFromId(trackType) {
  let trackTypeName = trackType;
  switch (trackType) {
    case `meditation`:
      trackTypeName = `track_type_meditation`;
      break;
    case `mindfulness`:
      trackTypeName = `track_type_meditation`;
      break;
    case `lifeCoaching`:
      trackTypeName = `track_type_life_coaching`;
      break;
    case `story`:
      trackTypeName = `track_type_story`;
      break;
    case `music`:
      trackTypeName = `track_type_music`;
      break;
    case `video`:
      trackTypeName = `track_type_video`;
      break;
    case `visualStory`:
      trackTypeName = `track_type_visual_story`;
      break;
    case `article`:
      trackTypeName = `track_type_article`;
      break;
    case `community`:
      trackTypeName = `track_type_community`;
      break;
    case `soundscape`:
      trackTypeName = `track_type_soundscape`;
      break;
    case `cognitiveBehavioralTheory`:
    case `therapy`:
      trackTypeName = `track_type_healthy_mind`;
      break;
    case `hypnosis`:
      trackTypeName = `track_type_hypnosis`;
      break;
    case `spirituality`:
      trackTypeName = `track_type_religin`;
      break;
    case `emotionalIntelligence`:
      trackTypeName = `track_type_emotional_intelligence`;
      break;
    case `asmr`:
      trackTypeName = `track_type_asmr`;
      break;
    case `poem`:
      trackTypeName = `track_type_poem`;
      break;
    case `breathwork`:
      trackTypeName = `track_type_breathwork`;
      break;
    case `natureSounds`:
      trackTypeName = `track_type_nature_sounds`;
      break;
    case `series`:
      trackTypeName = `track_type_series`;
      break;
    case `workWellness`:
      trackTypeName = `track_type_career_finances`;
      break;
    case `healthCoaching`:
      trackTypeName = `track_type_health_coaching`;
      break;
    case `sexualHealth`:
      trackTypeName = `track_type_intimacy_relationships`;
      break;
    case `diversityAndInclusion`:
      trackTypeName = `track_type_diversity_inclusion`;
      break;
    case `energyHealing`:
      trackTypeName = `track_type_alternative_healing`;
      break;
    case `motivation`:
      trackTypeName = `track_type_motivation`;
      break;
    case `affirmations`:
      trackTypeName = `track_type_affirmations`;
      break;
    case `personalStory`:
      trackTypeName = `track_type_personal_story`;
      break;
    case `podcast`:
      trackTypeName = `track_type_podcast`;
      break;
    case `course`:
      trackTypeName = `track_type_course`;
      break;
    default:
      trackTypeName = `track_type_meditation`;
      break;
  }
  return trackTypeName;
}

function uploadTypeDisplayValue(type) {
  let trackTypeName = trackTypeDisplayStringFromId(type);
  switch (type) {
    case `cognitiveBehavioralTheory`:
    case `therapy`:
      trackTypeName = `track_type_mental_health`;
      break;
    default:
      break;
  }
  return trackTypeName;
}

function getChannelAuthorPhoto(meditation, imageSize = 'photo100Url') {
  let authorPhotoUrl;
  if (
    meditation &&
    meditation.channel &&
    meditation.channel.authorPhotoThumbs &&
    meditation.channel.authorPhotoThumbs[imageSize]
  ) {
    return meditation.channel.authorPhotoThumbs[imageSize];
  }
  if (meditation && meditation.channel && meditation.channel.authorPhoto) {
    authorPhotoUrl = meditation.channel.authorPhoto;
  }
  return authorPhotoUrl;
}

function getChannelName(meditation) {
  let channelName = `Aura`;

  if (meditation && meditation.channel && meditation.channel.channelName) {
    ({ channelName } = meditation.channel);
  }
  return channelName;
}

async function editTrack({ id, ...data }) {
  if (!id) {
    throw new Error('No track id provided');
  }

  const authToken = await Auth.getUserAuthToken();

  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/contents/${id}`,
    data,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };
  return axios(options);
}

async function deleteTrack({ id }) {
  if (!id) {
    throw new Error('No track id provided');
  }

  const authToken = await Auth.getUserAuthToken();

  const options = {
    method: 'DELETE',
    url: `${config.api.auraServices}/contents/${id}`,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };

  try {
    return axios(options);
  } catch (error) {
    notifyAPIError(error, { message: 'Error deleting track', trackId: id });
    throw error;
  }
}

async function updateTrackStatus({ id, status, notes, rejectionType }) {
  if (!id) {
    throw new Error('No track id provided');
  }

  if (!contentConstants.STATUSES[status]) {
    Logger.error(
      'invalid track status provided to "updateTrackStatus" method',
      { id, status }
    );
    notifyHandledError(null, {
      message: 'invalid track status provided to "updateTrackStatus" method',
    });
    throw new Error('Invalid status');
  }

  const authToken = await Auth.getUserAuthToken();

  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/contents/qa/status/${id}`,
    data: { status, notes, rejectionType },
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };
  Analytics.track('Track Status Updated', {
    'Track ID': id,
    'Track Status': status,
  });
  return axios(options);
}

function getTrackReviewDisplayStatus({ status }) {
  if (!contentConstants.STATUSES[status]) {
    Logger.error(
      'invalid track status provided to "getTrackReviewDisplayStatus" method',
      { status }
    );
    notifyHandledError(null, {
      message:
        'invalid track status provided to "getTrackReviewDisplayStatus" method',
      status,
    });
    return null;
  }

  return contentConstants.STATUSES_DISPLAY[status];
}

function isWaitingForReview(track = {}) {
  const { status } = track;

  return (
    status === contentConstants.STATUSES.waitingForReview ||
    status === contentConstants.STATUSES.created
  );
}

function isWaitingForAudioEdit(track = {}) {
  const { status } = track;

  return status === contentConstants.STATUSES.waitingForAudioEdit;
}

function isWaitingForApproval(track = {}) {
  const { status } = track;

  return status === contentConstants.STATUSES.waitingForApproval;
}

function isApproved(track = {}) {
  const { status } = track;

  return status === contentConstants.STATUSES.approved;
}

function isRejected(track = {}) {
  const { status } = track;

  return status === contentConstants.STATUSES.rejected;
}

function getLatestReviewNotesFromStatusHistory({
  statusData = [],
  skipRejection,
} = {}) {
  let notes = '';
  if (Array.isArray(statusData)) {
    const latestStatusItemWithNotes = statusData.find((statusObj) =>
      Boolean(statusObj?.notes)
    );

    const canAssignNotes =
      !skipRejection ||
      (skipRejection &&
        latestStatusItemWithNotes &&
        latestStatusItemWithNotes.status &&
        latestStatusItemWithNotes.status !==
          contentConstants.STATUSES.rejected);

    if (latestStatusItemWithNotes && canAssignNotes) {
      notes = latestStatusItemWithNotes.notes;
    }
  }

  return notes;
}

async function getMeditationRejectionReasons() {
  try {
    const value = await FirebaseDatabase.getValue(`/trackRejectionTypes`);
    if (value !== null) {
      return value;
    }
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching track rejection types ',
    });
    return null;
  }
  return null;
}

function getContentReferralLink(track, coachDetails = {}, user = {}) {
  if (!coachDetails || !track) return null;
  const { slug } = coachDetails;

  const { slug: trackSlug, id: trackId, locale } = track || {};
  const { referralCode } = user || {};
  if (!slug) return null;
  if (canShowNewReferral(coachDetails) && referralCode)
    return `${config.userWeb}/${getUrlLocale(
      locale
    )}refer/${referralCode}/tracks/${trackId}`;
  const path = `${config.userWeb}/track/${trackSlug}`;
  const query = {
    referralCode,
    referralType: referralConstants.TYPE_COACH_BM_COACHING,
    utm_source: referralConstants.UTM_SOURCE_COACH_WEB,
  };
  return generateExternalUrlQueryPath(path, query);
}

export {
  getMeditation,
  listMeditations,
  createMeditation,
  deleteTrack,
  getTrackPublicUrl,
  isTrackInReview,
  filterApprovedTracks,
  getUnapprovedMeditations,
  getMeditationStatus,
  getMeditationPhoto,
  getMeditationPosts,
  getMeditationDisplayDuration,
  getTrackUrl,
  getSecondsDuration,
  getSimplifiedDuration,
  getChannelPhotoUrl,
  getChannelAuthorName,
  trackTypeDisplayStringFromId,
  uploadTypeDisplayValue,
  getChannelAuthorPhoto,
  getChannelName,
  editTrack,
  updateTrackStatus,
  getTrackReviewDisplayStatus,
  isWaitingForReview,
  isWaitingForAudioEdit,
  isWaitingForApproval,
  isApproved,
  isRejected,
  getLatestReviewNotesFromStatusHistory,
  sendTrackChangesToAnalytics,
  uploadTrackList,
  createSeries,
  isSeriesInReview,
  getMeditationRejectionReasons,
  getMeditationIdFromSlug,
  getApprovedMeditations,
  getContentReferralLink,
};
