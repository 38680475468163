import axios from 'axios';

import Auth from '../services/FirebaseAuth';

import config from '../config';
import { notifyAPIError } from '../services/ErrorMonitoring';
import { generateExternalUrlQueryPath } from '../utils';
import referralConstants from '../utils/constants/referralConstants';

async function listServices(coachId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/services/list`,
      data: { coachId },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching Services list' });
    return null;
  }
}

async function getDurationDetails(sessionType) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/availability/sessionTypes/${sessionType}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching duration details' });
    return null;
  }
}

function getServiceLink(serviceId, coach, user) {
  if (!coach) return null;
  const { slug } = coach;
  const { referralCode } = user || {};
  if (!slug) return null;
  const path = `${config.userWeb}/coaches/${slug}/coaching-session`;
  const query = {
    serviceId,
    referralCode,
    referralType: referralConstants.TYPE_COACH_BM_COACHING,
    utm_source: referralConstants.UTM_SOURCE_COACH_WEB,
  };
  return generateExternalUrlQueryPath(path, query);
}

async function createService(serviceData) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/services`,
      data: serviceData,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error adding service' });
    return null;
  }
}

async function deleteService(serviceId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/scheduling/services/${serviceId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error deleting service' });
    return null;
  }
}

async function deleteUpcomingService(appointmentId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/scheduling/appointments/${appointmentId}/cancel`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error deleting upcoming service' });
    return null;
  }
}

async function updateService({ serviceId, data }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/scheduling/services/${serviceId}`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error updating service' });
    return null;
  }
}

const getPricingDetail = ({ pricing, key }) => {
  let pricingText;
  if (pricing && pricing.length > 0) {
    const sortedByDuration = pricing
      .filter((item) => item.price !== null)
      .sort((a, b) => {
        return a[key] - b[key];
      });
    if (sortedByDuration.length === 1) {
      pricingText =
        key === 'duration'
          ? `${sortedByDuration[0].duration} min`
          : sortedByDuration[0].price / 100;
    }
    if (sortedByDuration.length > 1) {
      pricingText =
        key === 'duration'
          ? `${sortedByDuration[0].duration} - ${
              sortedByDuration[sortedByDuration.length - 1].duration
            } min`
          : `${sortedByDuration[0].price / 100}-${
              sortedByDuration[sortedByDuration.length - 1].price / 100
            }`;
    }
  }
  return pricingText;
};

export {
  listServices,
  getDurationDetails,
  createService,
  deleteService,
  updateService,
  deleteUpcomingService,
  getPricingDetail,
  getServiceLink,
};
