import axios from 'axios';
import FirebaseDatabase from '../services/FirebaseDatabase';
import Logger from '../services/Logger';
import Auth from '../services/FirebaseAuth';
import {
  notifyAPIError,
  notifyHandledError,
} from '../services/ErrorMonitoring';

import config from '../config';

async function listChannels() {
  Logger.debug('channels: list');
  const channels = [];

  try {
    const response = await FirebaseDatabase.getValue(`/channels`);

    if (response && Object.keys(response).length) {
      Object.keys(response).forEach((key) => {
        const channel = { id: key, ...response[key] };
        // Don't put inactive check since we display inactive channels for edit, upload
        if (channel.channelName && !channel.deleted) {
          delete channel.subscribers;
          channels.push(channel);
        }
      });
    }
  } catch (error) {
    notifyHandledError(error, { message: 'Error fetching channels' });
    return null;
  }

  return channels;
}

function filterChannels({ channels, query, userProfile }) {
  Logger.debug('filter channels', { query });
  if (!query) {
    return channels;
  }
  const { type, category } = query;
  let filteredChannels = channels;
  if (type) {
    let trackType = type;
    if (trackType === `meditation`) {
      trackType = `mindfulness`;
    }
    filteredChannels = channels.filter((channel) => {
      let channelType = `mindfulness`;
      if (channel.channelType) {
        ({ channelType } = channel);
      }
      return channelType === trackType;
    });
  }
  if (category) {
    if (category === 'topRated') {
      filteredChannels = filteredChannels.filter((channel) => {
        return channel.analytics && channel.analytics.averageRating;
      });
      filteredChannels.sort(
        (a, b) => b.analytics.averageRating - a.analytics.averageRating
      );
      if (userProfile && userProfile.channels) {
        filteredChannels = filteredChannels.filter((channel) => {
          if (userProfile.channels[channel.key]) {
            return false;
          }
          return true;
        });
      }
    }
  }
  return filteredChannels;
}

async function getChannel(id) {
  Logger.debug(`channels: get ${id}`);

  try {
    const channel = await FirebaseDatabase.getValue(`/channels/${id}`);

    if (channel && !channel.deleted) {
      channel.id = id;
      delete channel.subscribers;
      return channel;
    }
    return null;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching channel',
      channelId: id,
    });
    return null;
  }
}

async function createChannel({ userId, data }) {
  const authToken = await Auth.getUserAuthToken();

  const channelData = { userId, ...data };

  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/channels`,
    data: channelData,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };

  return axios(options);
}

async function handleUpdateChannel({ id, data }) {
  const authToken = await Auth.getUserAuthToken();

  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/channels/${id}`,
    data,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };
  return axios(options).catch((error) => {
    notifyAPIError(error, { message: 'Error update channel' });
    throw error;
  });
}

function getChannelPhoto(channel, size) {
  const channelPhotoUrl = `https://firebasestorage.googleapis.com/v0/b/firebase-auratech16.appspot.com/o/auraTrackPic.png?alt=media&token=06b53bb7-8f44-4da0-a47a-82d94c030fbb`;
  if (!channel) {
    return channelPhotoUrl;
  }
  if (
    size &&
    channel.channelPictureThumbs &&
    channel.channelPictureThumbs[size]
  ) {
    return channel.channelPictureThumbs[size];
  }
  if (channel.channelPicture) {
    return channel.channelPicture;
  }
  return channelPhotoUrl;
}

function getAuthorPhoto(channel, imageSize = 'photo100Url') {
  let authorPhotoUrl;
  if (
    channel &&
    channel.authorPhotoThumbs &&
    channel.authorPhotoThumbs[imageSize]
  ) {
    return channel.authorPhotoThumbs[imageSize];
  }
  if (channel && channel.authorPhoto) {
    authorPhotoUrl = channel.authorPhoto;
  }
  return authorPhotoUrl;
}

function normalizeChannelsArray(channelsArray = []) {
  const channels = {};

  channelsArray.forEach((channel) => {
    if (channel) {
      channels[channel.id] = channel;
    }
  });

  return channels;
}

export {
  getChannel,
  createChannel,
  handleUpdateChannel,
  listChannels,
  filterChannels,
  getChannelPhoto,
  getAuthorPhoto,
  normalizeChannelsArray,
};
