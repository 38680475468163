import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagReactPlugin from '@bugsnag/plugin-react';

import config from '../config';

const BugsnagClient = Bugsnag.start({
  plugins: [new BugsnagReactPlugin()],
  apiKey: config.bugsnag.apiKey,
  appVersion: config.bugsnag.appVersion,
  releaseStage: config.bugsnag.releaseStage,
});

export const ErrorBoundary =
  Bugsnag.getPlugin('react').createErrorBoundary(React);

export default BugsnagClient;
