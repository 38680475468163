import axios from 'axios';

import { addMinutes, differenceInSeconds, subMinutes } from 'date-fns';
import Auth from '../services/FirebaseAuth';

import config from '../config';
import Logger from '../services/Logger';
import { notifyAPIError } from '../services/ErrorMonitoring';
import {
  convertToTimezone,
  convertToUTC,
  getTimeDifference,
} from '../utils/date';
import I18NFormatter from '../services/I18NFormatter';

function isTemplate({ type }) {
  return type === 'template';
}

function updateScheduledAt(
  scheduledAt = new Date().toISOString(),
  timezone,
  { date, time }
) {
  let userScheduledAt = convertToTimezone(scheduledAt, timezone);
  if (date) {
    userScheduledAt = `${I18NFormatter.formatDate(
      date,
      'yyyy-MM-dd'
    )}T${userScheduledAt.substring(11)}`;
  }
  if (time) {
    userScheduledAt = `${userScheduledAt.substring(0, 11) + time}:00.000Z`;
  }
  return convertToUTC(userScheduledAt, timezone);
}

function getTemplateDuration(template) {
  if (!template) return null;
  const { data } = template;
  if (!data || !data.length) return null;
  const lastDay = data[data.length - 1];
  const numberOfDays = lastDay.relativeDay + 1;
  if (numberOfDays < 7) {
    return `${lastDay.relativeDay + 1} days`;
  }
  return `${Math.ceil(numberOfDays / 7)} weeks`;
}

function getDayArray(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

function getTemplateDayOptions(template) {
  const { data } = template;
  if (!data || !data.length) {
    return getDayArray(0, 13);
  }
  const startNumber = 1;
  const endNumber = data[data.length - 1].relativeDay + 13;
  const dayArray = getDayArray(startNumber, endNumber);
  return dayArray;
}

function getScheduledAtRange(schedule, index) {
  const { data } = schedule;
  const range = {};
  if (!data || !data.length || !index) {
    range.before = addMinutes(new Date(), 10);
    return range;
  }
  if (index <= 0) {
    range.before = addMinutes(new Date(), 10);
  } else {
    const startDate = data[index - 1].scheduledAt;
    range.before = addMinutes(new Date(startDate), 10);
  }
  if (index < data.length - 1) {
    const endDate = data[index + 1].scheduledAt;
    range.after = subMinutes(new Date(endDate), 10);
  }
  return range;
}

async function listTemplates() {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/homeworks/templates/list`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error fetching templates list', {
      error,
    });
    notifyAPIError(error);
    return null;
  }
}

async function getTemplate(templateId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/coaching/homeworks/templates/${templateId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error getting a template', {
      error,
    });
    notifyAPIError(error);
    return null;
  }
}

async function updateTemplate({ id, data }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/coaching/homeworks/templates/${id}`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error updating a template', {
      error,
    });
    notifyAPIError(error);
    return null;
  }
}

async function duplicateTemplate(templateId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/homeworks/templates/${templateId}/duplicate`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error duplicating a template', {
      error,
    });
    notifyAPIError(error);
    return null;
  }
}

async function addNewTemplate(data) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/homeworks/templates`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error adding new template', {
      error,
      transactionSlice: error?.response?.headers?.['x-response-id-slice'],
    });
    notifyAPIError(error);
    return null;
  }
}

async function deleteTemplate(templateId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/coaching/homeworks/templates/${templateId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response;
    }
    return null;
  } catch (error) {
    Logger.error('Error deleting a template', {
      error,
      transactionSlice: error?.response?.headers?.['x-response-id-slice'],
    });
    notifyAPIError(error);
    return null;
  }
}

async function updateTemplateItem({
  relativeDay,
  time,
  data,
  templateItemId,
  templateId,
}) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const updateData = {
      relativeDay,
      time,
      data,
      templateItemId,
    };
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/coaching/homeworks/templates/${templateId}/modify`,
      data: updateData,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error updating a template item', {
      error,
      transactionSlice: error?.response?.headers?.['x-response-id-slice'],
    });
    notifyAPIError(error);
    return null;
  }
}

async function addNewTemplateItem({ templateId, newTemplateItem }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/homeworks/templates/${templateId}/modify`,
      data: newTemplateItem,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error adding a new template item', {
      error,
      transactionSlice: error?.response?.headers?.['x-response-id-slice'],
    });
    notifyAPIError(error);
    return null;
  }
}

async function deleteTemplateItem({ templateId, templateItemId }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/coaching/homeworks/templates/${templateId}/modify`,
      data: {
        templateItemId,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error deleting a template item', {
      error,
      transactionSlice: error?.response?.headers?.['x-response-id-slice'],
    });
    notifyAPIError(error);
    return null;
  }
}

async function handleUseTemplate({ userId, templateId, startDate }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/homeworks/schedules/${userId}/useTemplate`,
      data: {
        templateId,
        start: startDate,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error using a template', {
      error,
      transactionSlice: error?.response?.headers?.['x-response-id-slice'],
    });
    notifyAPIError(error);
    return null;
  }
}

async function removeTemplateFromSchedule({ userId, templateId }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/coaching/homeworks/schedules/${userId}/template/${templateId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error removing a template', {
      error,
      transactionSlice: error?.response?.headers?.['x-response-id-slice'],
    });
    notifyAPIError(error);
    return null;
  }
}

async function getUserScheduleList(userId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/homeworks/schedules/${userId}/list`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        type: 'all',
      },
      json: true,
    };
    const response = await axios(options);
    Logger.debug('Received user schedule', { schedule: response.data });
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error getting user schedule', {
      error,
      transactionSlice: error?.response?.headers?.['x-response-id-slice'],
    });
    notifyAPIError(error);
    return null;
  }
}

async function createUserSchedule({
  userId,
  schedule,
  scheduledAt,
  sentAt,
  sentId,
}) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/homeworks/schedules/${userId}/modify`,
      data: {
        data: schedule,
        scheduledAt,
        type: 'all',
        sentAt,
        sentId,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error creating user schedule', {
      error,
      transactionSlice: error?.response?.headers?.['x-response-id-slice'],
    });
    notifyAPIError(error);
    return null;
  }
}

async function updateUserSchedule({
  userId,
  scheduleId,
  schedule,
  scheduledAt,
}) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const data = {
      scheduleId,
      data: schedule,
      scheduledAt,
      type: 'all',
    };
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/coaching/homeworks/schedules/${userId}/modify`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error updating user schedule', {
      error,
      transactionSlice: error?.response?.headers?.['x-response-id-slice'],
    });
    notifyAPIError(error);
    return null;
  }
}

async function deleteSchedule(userId, scheduleId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/coaching/homeworks/schedules/${userId}/modify`,
      data: {
        scheduleId,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    Logger.error('Error deleting user schedule', {
      error,
      transactionSlice: error?.response?.headers?.['x-response-id-slice'],
    });
    notifyAPIError(error);
    return null;
  }
}

function sortSchedule(schedule, type) {
  if (!schedule || !schedule.data) {
    Logger.info('No schedule to sort', { schedule });
    return schedule;
  }
  schedule.data.sort((a, b) => {
    if (type === 'template') {
      return a.relativeDay - b.relativeDay || getTimeDifference(a.time, b.time);
    }
    return new Date(a.scheduledAt) - new Date(b.scheduledAt);
  });
  return schedule;
}

function assignTemplateToScheduleDay(scheduleDay, { schedule, dayIndex }) {
  if (!scheduleDay) {
    Logger.warn('No schedule day for template assignment', {
      scheduleDay,
      dayIndex,
    });
  }
  const updatedScheduleDay = { ...scheduleDay };
  const { templateId } = updatedScheduleDay;
  const { templateId: previousTemplateId, templateName: previousTemplateName } =
    schedule.data?.[dayIndex - 1] || {};
  const { templateId: nextTemplateId } = schedule.data?.[dayIndex + 1] || {};
  // If schedule day has no template assigned, just assign the template of previous schedule day
  if (!templateId) {
    if (previousTemplateId) {
      updatedScheduleDay.templateId = previousTemplateId;
      updatedScheduleDay.templateName = previousTemplateName;
    }
    return updatedScheduleDay;
  }
  // If schedule day template matches any adjacent schedule day template, keep current template
  if (templateId === previousTemplateId) return updatedScheduleDay;
  if (templateId === nextTemplateId) return updatedScheduleDay;
  // If schedule day has different template, assign template from previous day
  if (previousTemplateId) {
    updatedScheduleDay.templateId = previousTemplateId;
    updatedScheduleDay.templateName = previousTemplateName;
    return updatedScheduleDay;
  }
  // Adjacent schedule days not belong to same template. Check if current schedule day is the only template item
  let templateItemCount = 0;
  schedule.data.forEach((day) => {
    if (day.templateId === templateId) {
      templateItemCount++;
    }
  });
  if (templateItemCount === 1) return updatedScheduleDay;
  // Schedule day no longer belongs to any template, remove
  updatedScheduleDay.templateId = null;
  updatedScheduleDay.templateName = null;
  return updatedScheduleDay;
}

function validateAndUpdateScheduleDay(
  scheduleDay,
  { dayIndex, schedule, type, assignTemplate = false }
) {
  if (!schedule) {
    Logger.warn('No schedule data', { scheduleDay });
    return null;
  }
  if (!scheduleDay) {
    Logger.warn('No schedule day for validation', { scheduleDay });
    return null;
  }
  if (!type) {
    Logger.warn('No type for validation', { type });
    return null;
  }

  const { relativeDay, scheduledAt, time } = scheduleDay;
  let updatedSchedule = { ...schedule };
  if (schedule?.data) {
    updatedSchedule.data = [...schedule.data];
  }
  let sortedDayIndex = dayIndex;
  if (typeof sortedDayIndex === 'number') {
    updatedSchedule.data[dayIndex] = scheduleDay;
    updatedSchedule = sortSchedule(updatedSchedule, type);
    sortedDayIndex = updatedSchedule.data.findIndex((day) =>
      isTemplate({ type })
        ? time && day.relativeDay === relativeDay && day.time === time
        : scheduledAt && day.scheduledAt === scheduledAt
    );
  }

  const previousScheduleDay = schedule
    ? updatedSchedule.data[sortedDayIndex - 1]
    : null;
  const nextScheduleDay = schedule
    ? updatedSchedule.data[sortedDayIndex + 1]
    : null;
  const handleError = (error) => {
    Logger.warn(error, {
      scheduledAt,
      previousScheduledAt: previousScheduleDay?.scheduledAt,
      nextScheduledAt: nextScheduleDay?.scheduledAt,
      sortedDayIndex,
      previousScheduleDay,
      nextScheduleDay,
      scheduleDay,
      updatedSchedule,
    });
    return { error };
  };
  const collidingTimeMessage =
    'Schedule day should have a difference of at least 10 minutes from existing schedule days';

  if (type === 'template') {
    if (typeof relativeDay !== 'number' || relativeDay < 0) {
      Logger.warn('Schedule missing relative day', { scheduleDay });
      return null;
    }
    if (
      previousScheduleDay &&
      relativeDay === previousScheduleDay.relativeDay &&
      Math.abs(getTimeDifference(previousScheduleDay.time, time)) < 600
    ) {
      return handleError(collidingTimeMessage);
    }
    if (
      nextScheduleDay &&
      relativeDay === nextScheduleDay.relativeDay &&
      Math.abs(getTimeDifference(nextScheduleDay.time, time)) < 600
    ) {
      return handleError(collidingTimeMessage);
    }
  }
  if (type === 'schedule') {
    if (typeof scheduledAt !== 'string') {
      Logger.warn('Schedule missing scheduledAt date', { scheduleDay });
      return null;
    }
    if (differenceInSeconds(new Date(scheduledAt), new Date()) < 600) {
      return handleError('Schedule must be 10 minutes after current time');
    }
    if (
      previousScheduleDay &&
      Math.abs(
        differenceInSeconds(
          new Date(scheduledAt),
          new Date(previousScheduleDay.scheduledAt)
        )
      ) < 600
    ) {
      return handleError(collidingTimeMessage);
    }
    if (
      nextScheduleDay &&
      Math.abs(
        differenceInSeconds(
          new Date(nextScheduleDay.scheduledAt),
          new Date(scheduledAt)
        )
      ) < 600
    ) {
      return handleError(collidingTimeMessage);
    }
    if (assignTemplate) {
      const updatedScheduleDay = assignTemplateToScheduleDay(scheduleDay, {
        schedule: updatedSchedule,
        dayIndex: sortedDayIndex,
      });
      updatedSchedule.data[sortedDayIndex] = updatedScheduleDay;
    }
  }
  return updatedSchedule;
}

export {
  isTemplate,
  updateScheduledAt,
  getTemplateDuration,
  deleteSchedule,
  listTemplates,
  getTemplate,
  updateTemplate,
  duplicateTemplate,
  addNewTemplate,
  deleteTemplate,
  addNewTemplateItem,
  updateTemplateItem,
  deleteTemplateItem,
  handleUseTemplate,
  removeTemplateFromSchedule,
  getTemplateDayOptions,
  getScheduledAtRange,
  getUserScheduleList,
  createUserSchedule,
  updateUserSchedule,
  sortSchedule,
  assignTemplateToScheduleDay,
  validateAndUpdateScheduleDay,
};
