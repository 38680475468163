import {
  ref,
  get,
  onValue,
  off,
  set,
  update,
  push,
  remove,
  onChildAdded,
} from 'firebase/database';
import { notifyHandledError } from './ErrorMonitoring';
import FirebaseApp from './FirebaseApp';

const { Database } = FirebaseApp;

async function getValue(path) {
  try {
    if (!path) return null;
    const snapshot = await get(ref(Database, path));
    const stats = snapshot.val();
    return stats;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Failed to get value from Firebase',
      path,
    });
    return null;
  }
}

async function getSnapshot(path) {
  try {
    if (!path) return null;
    const snapshot = await get(ref(Database, path));
    return snapshot;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error getting snapshot from Firebase',
      path,
    });
    return null;
  }
}
function getRef(path) {
  return ref(Database, path);
}

async function setValue(path, value) {
  try {
    if (!path) return null;
    const response = await set(ref(Database, path), value);
    return response;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error setting value in Firebase',
      path,
      value,
    });
    return null;
  }
}

async function updateValue(path, value) {
  try {
    if (!path) return null;
    const response = await update(ref(Database, path), value);
    return response;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error updating value in Firebase',
      path,
      value,
    });
    return null;
  }
}

async function removeValue(path) {
  try {
    if (!path) return null;
    const response = await remove(ref(Database, path));
    return response;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error removing value in Firebase',
      path,
    });
    return null;
  }
}

async function pushValue(path, value) {
  try {
    if (!path) return null;
    const response = await push(ref(Database, path), value);
    return response;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error pushing value to Firebase',
      path,
    });
    return null;
  }
}

async function generateId(path) {
  try {
    if (!path) return null;
    const res = await push(ref(Database, path));
    return res?.key;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error generating push id in Firebase',
      path,
    });
    return null;
  }
}

const FirebaseDatabase = {
  getValue,
  getSnapshot,
  getRef,
  onValue,
  off,
  setValue,
  updateValue,
  generateId,
  onChildAdded,
  removeValue,
  pushValue,
};

export default FirebaseDatabase;
