import axios from 'axios';

import Auth from '../services/FirebaseAuth';
import FirebaseDatabase from '../services/FirebaseDatabase';
import config from '../config';
import BugsnagClient from '../services/Bugsnag';
import {
  notifyAPIError,
  notifyHandledError,
} from '../services/ErrorMonitoring';

let appointmentListenerRef = null;

async function setupAppointmentListener(
  appointmentId,
  onAppointmentDataReceived
) {
  try {
    if (appointmentListenerRef) {
      FirebaseDatabase.off(appointmentListenerRef);
    }

    appointmentListenerRef = FirebaseDatabase.getRef(
      `/appointments/${appointmentId}`
    );
    FirebaseDatabase.onValue(appointmentListenerRef, (snapshot) => {
      const liveData = snapshot.val();

      if (liveData) {
        onAppointmentDataReceived(liveData);
      } else {
        BugsnagClient.leaveBreadcrumb(
          `Received null appointment in listener ${appointmentId}`
        );
      }
    });
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching appointment data',
      appointmentId,
    });
  }
}

async function updateAppointmentData({ appointmentId, data }) {
  try {
    if (!appointmentId || !data) {
      notifyHandledError(null, {
        message: 'Missing appointment id or data for update',
        appointmentId,
        data,
      });
      return null;
    }
    const appointmentData = { ...data };

    if (appointmentData?.id) {
      delete appointmentData.id;
    }

    await FirebaseDatabase.updateValue(
      `/appointments/${appointmentId}`,
      appointmentData
    );
    return true;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error updating appointment details',
    });
    return null;
  }
}

async function clearAppointmentListener() {
  try {
    if (appointmentListenerRef) {
      FirebaseDatabase.off(appointmentListenerRef);
    }
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while clearing live activity listener',
    });
  }
}

async function getScheduledAppointmentList(coachId) {
  const authToken = await Auth.getUserAuthToken();
  const data = {
    coachId,
  };

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/appointments/list`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while getting appointment list' });
    return null;
  }
}

async function getAppointmentAgoraAccessToken(appointmentId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/appointments/${appointmentId}/accessToken`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while getting appointment agora access token',
    });
    return null;
  }
}

async function getAppointment(appointmentId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/appointments/${appointmentId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while getting appointment',
    });
    return null;
  }
}

const filterByStatus = (status, list) => {
  return list.filter((appointment) => appointment?.status === status);
};

const filterBySessionType = (anySessionTypes, list) => {
  return list.filter((appointment) =>
    anySessionTypes.includes(appointment?.sessionTypeId)
  );
};

const filterByUserId = (userId, list) => {
  return list.filter((appointment) => appointment?.userId === userId);
};

const filterByStartDate = (start, list) => {
  return list.filter(
    (appointment) => new Date(appointment?.start) >= new Date(start)
  );
};

const filterByEndDate = (end, list) => {
  return list.filter(
    (appointment) => new Date(appointment?.end) >= new Date(end)
  );
};

export {
  getScheduledAppointmentList,
  getAppointmentAgoraAccessToken,
  getAppointment,
  filterBySessionType,
  filterByUserId,
  filterByStartDate,
  filterByStatus,
  filterByEndDate,
  setupAppointmentListener,
  clearAppointmentListener,
  updateAppointmentData,
};
