import Logger from './Logger';

const isLocalStorageAvailable = () => {
  try {
    const storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

function setItem(key, value) {
  if (!isLocalStorageAvailable()) {
    Logger.warn('Local storage is not available', {
      method: 'setItem',
      key,
      value,
    });
    return;
  }
  if (!key) {
    return;
  }
  Logger.debug('storing to local', { key, value });
  window.localStorage.setItem(key, JSON.stringify(value));
}

function removeItem(key) {
  if (!isLocalStorageAvailable()) {
    Logger.warn('Local storage is not available', {
      method: 'removeItem',
      key,
    });
    return;
  }
  if (!key) {
    return;
  }
  window.localStorage.removeItem(key);
}

function getItem(key) {
  if (!isLocalStorageAvailable()) {
    Logger.warn('Local storage is not available', {
      method: 'getItem',
      key,
    });
    return null;
  }
  if (!key) {
    return null;
  }
  let value = window.localStorage.getItem(key);
  if (value) {
    value = JSON.parse(value);
  }
  Logger.debug('fetched from local', { key, value });
  return value;
}

const KEYS = {
  COACH_CODE: 'COACH_CODE',
  COACHING_TYPE: 'COACHING_TYPE',
  LOGIN_EVENT_SENT: 'LOGIN_EVENT_SENT',
  LAST_LOGIN: 'LAST_LOGIN',
  VIEWED_LIVE_LANDING: 'VIEWED_LIVE_LANDING',
  RECOMMEND_VIDEOCALL_RAINBOW_BAR: 'RECOMMEND_VIDEOCALL_RAINBOW_BAR',
  LAST_CONTENT_UPLOAD_LOCALE: 'LAST_CONTENT_UPLOAD_LOCALE',
};

const LocalStorage = {
  isAvailable: isLocalStorageAvailable,
  setItem,
  removeItem,
  getItem,
  KEYS,
};

export default LocalStorage;
