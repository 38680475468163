import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { listCommunities } from '../../models/communities';
import { notifyHandledError } from '../../services/ErrorMonitoring';

export const getCommunitiesList = createAsyncThunk(
  'communities/getCommunitiesList',
  async (data, { rejectWithValue }) => {
    try {
      const communityList = await listCommunities(data);

      return communityList;
    } catch (error) {
      notifyHandledError(error, { message: ' Unable to get communities list' });
      return rejectWithValue();
    }
  }
);

const initialState = {
  list: [],
  isLoading: false,
  error: false,
  selectedCommunity: null,
  isFetched: false,
};

/* eslint-disable no-param-reassign */
export const communitiesSlice = createSlice({
  name: 'communities',
  initialState,
  reducers: {
    addNewCommunity: (state, action) => {
      state.list = [action.payload, ...state.list];
    },
    setSelectedCommunity: (state, action) => {
      state.isLoading = false;
      state.selectedCommunity = action.payload;
    },
    updateCommunityList: (state, action) => {
      const { list, selectedCommunity } = state;
      const index = list.findIndex((item) => item.id === action.payload.id);

      const newArray = [...list];
      newArray.splice(index, 1, action.payload);

      newArray.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      if (selectedCommunity?.id === action.payload.id) {
        state.selectedCommunity = { ...selectedCommunity, ...action.payload };
      }

      state.list = newArray;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommunitiesList.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.list = null;
      })

      .addCase(getCommunitiesList.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isLoading = false;
        state.error = false;
        state.isFetched = true;
      })

      .addCase(getCommunitiesList.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const { addNewCommunity, setSelectedCommunity, updateCommunityList } =
  communitiesSlice.actions;

export default communitiesSlice.reducer;
