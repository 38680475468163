const {
  format,
  parseISO,
  subMilliseconds,
  addMilliseconds,
} = require('date-fns');
const { en } = require('date-fns/locale');
const { utcToZonedTime, getTimezoneOffset } = require('date-fns-tz');

function displayInTimezone(
  isoDate,
  timezone,
  displayFormat = 'YYYY-MM-dd',
  locale = en
) {
  return format(utcToZonedTime(isoDate, timezone), displayFormat, {
    timeZone: timezone,
    locale,
  });
}

function convertToTimezone(isoDate, timezone) {
  const offset = getTimezoneOffset(timezone, parseISO(isoDate));
  return addMilliseconds(parseISO(isoDate), offset).toISOString();
}

function convertToUTC(isoDate, timezone) {
  const offset = getTimezoneOffset(timezone, parseISO(isoDate));
  return subMilliseconds(parseISO(isoDate), offset).toISOString();
}

function convertTimeToMinutes(time) {
  const [hour, minutes] = time.split(':').map((val) => parseInt(val, 10));
  return hour * 60 + minutes;
}

function displayTimeInTimezone(time, fromTimezone, toTimezone) {
  const offsetInMinutes =
    getTimezoneOffset(toTimezone) / 60000 -
    getTimezoneOffset(fromTimezone) / 60000;
  const originalTime = convertTimeToMinutes(time);
  let timezoneTime = originalTime + offsetInMinutes;
  const day = 24 * 60;
  if (timezoneTime < 0) {
    timezoneTime = day + timezoneTime;
  }
  if (timezoneTime > day) {
    timezoneTime -= day;
  }
  const hours = Math.floor(timezoneTime / 60);
  const min = timezoneTime % 60;
  return `${`0${hours}`.slice(-2)}:${`0${min}`.slice(-2)}`;
}

function convertTimeTo12Hour(time) {
  const hour = time.split(':')[0];
  const minute = time.split(':')[1];
  const numberHour = parseInt(hour, 10);
  const displayHour = numberHour % 12 || 12;

  return `${displayHour}:${minute} ${numberHour >= 12 ? 'PM' : 'AM'}`;
}

function convertTimeToMilitaryFormat(time, end) {
  let hour = parseInt(time.split(':')[0], 10);
  const minute = parseInt(time.split(':')[1], 10);
  if (hour === 0 && minute === 0 && end) hour = 24;
  return hour * 100 + minute;
}

function getTimeDifference(time1, time2) {
  const time1Value = convertTimeToMinutes(time1);
  const time2Value = convertTimeToMinutes(time2);
  return (time1Value - time2Value) * 60;
}

function getTimezoneDifference(timezone1, timezone2) {
  const tzOffset1 = getTimezoneOffset(timezone1);
  const tzOffset2 = getTimezoneOffset(timezone2);
  const difference = Math.abs((tzOffset1 - tzOffset2) / 1000);
  const hr = Math.floor(difference / 3600);
  const min = Math.floor((difference - hr * 3600) / 60);
  return `${hr} hour ${min} minutes`;
}

function isDateInThisWeek(date, firstDayOfWeek, lastDayOfWeek) {
  if (!date || !firstDayOfWeek || !lastDayOfWeek) return false;
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

module.exports = {
  convertTimeTo12Hour,
  convertTimeToMinutes,
  convertToTimezone,
  convertToUTC,
  displayInTimezone,
  displayTimeInTimezone,
  getTimeDifference,
  getTimezoneDifference,
  convertTimeToMilitaryFormat,
  isDateInThisWeek,
};
