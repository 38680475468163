import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { addMinutes } from 'date-fns';
import { liveEventActivity } from '../../utils/constants/live';
import Logger from '../../services/Logger';
import {
  getLiveSession,
  getLiveEventList,
  getCoachLiveEventSummary,
  getLiveEventAnalyticsList,
  updateLiveCheckList,
} from '../../models/live';
import { getCoach } from '../../models/coach';
import { notifyHandledError } from '../../services/ErrorMonitoring';

export const getLiveData = createAsyncThunk(
  'live/getLiveData',
  async (liveId) => {
    Logger.debug('Fetching live data');

    const result = await getLiveSession(liveId);
    return result;
  }
);

export const getUpcomingLiveSession = createAsyncThunk(
  'live/getUpcomingLiveSession',
  async (coachId, { rejectWithValue }) => {
    try {
      const result = await getLiveEventList({
        coachId,
        anyStatus: ['scheduled', 'active', 'paused'],
        start: new Date(new Date().setHours(0, 0, 0, 0)),
        includeTest: true,
      });
      if (!result) rejectWithValue();
      return result;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const getPastLiveSession = createAsyncThunk(
  'live/getPastLiveSession',
  async (coachId, { rejectWithValue }) => {
    try {
      const result = await getLiveEventList({
        coachId,
        anyStatus: ['ended'],
      });
      const liveSummary = await getCoachLiveEventSummary(coachId);
      if (result) {
        const analyticsList = await getLiveEventAnalyticsList(coachId);

        const analytics = result.map((item) => {
          return {
            ...item,
            analytics:
              analyticsList && analyticsList[item.id]
                ? analyticsList[item.id]
                : null,
          };
        });

        return {
          analytics,
          liveSummary,
        };
      }
      return rejectWithValue();
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const getFullLiveEventSchedule = createAsyncThunk(
  'live/getFullLiveEventSchedule',
  async (_, { rejectWithValue }) => {
    try {
      const result = await getLiveEventList({
        start: new Date(new Date().setHours(0, 0, 0, 0)),
      });

      if (result) {
        const eventList = [];
        const promises = result.map(async (item) => {
          const coachDetails = await getCoach(item.coachId);
          eventList.push({
            ...item,
            coachDetails,
          });
        });
        await Promise.all(promises);

        return eventList;
      }
      return rejectWithValue();
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const updateLiveEventChecklist = createAsyncThunk(
  'live/updateLiveEventChecklist',
  async (key, { rejectWithValue, getState }) => {
    try {
      const {
        liveEvent: { liveEventDetails, selectedLiveEvent },
      } = getState();
      const { liveCheckList = {} } = liveEventDetails || {};
      const { id: liveId } = selectedLiveEvent || {};
      const updatedChecklist = { ...liveCheckList };
      if (liveCheckList[key] === true) updatedChecklist[key] = false;
      else updatedChecklist[key] = true;
      await updateLiveCheckList(liveId, key, updatedChecklist[key]);
      return { liveCheckList: updatedChecklist };
    } catch (error) {
      Logger.error('Error while updating live event checklist', { error });
      return rejectWithValue({ error });
    }
  }
);

const initialState = {
  isLoading: false,
  error: false,
  upcomingLiveEvents: {
    list: [],
    isLoading: false,
    isFetched: false,
    error: false,
  },
  pastLiveEvents: {
    list: [],
    isLoading: false,
    isFetched: false,
    error: false,
  },
  fullLiveEventSchedule: {
    list: [],
    isLoading: false,
    isFetched: false,
    error: false,
  },
  coachLiveSummary: null,
  selectedLiveEvent: null,
  liveEventDetails: null,
  videoRecorderError: false,
  editLiveEvent: null,
  chatActivity: [],
  feedActivity: [],
  attendeesList: [],
  likesActivity: [],
  onlineAttendeesCount: 0,
};

/* eslint-disable no-param-reassign */
export const liveSlice = createSlice({
  name: 'live',
  initialState,
  reducers: {
    setSelectedLiveData: (state, action) => {
      state.isLoading = false;
      state.selectedLiveEvent = action.payload;
    },
    addNewUpcomingLive: (state, action) => {
      state.upcomingLiveEvents.list.push(action.payload);
      state.upcomingLiveEvents.list.sort(
        (a, b) => new Date(a.scheduledAt) - new Date(b.scheduledAt)
      );
    },
    updateLiveEvent: (state, action) => {
      const index = state.upcomingLiveEvents.list.findIndex(
        (item) => item.id === action.payload.id
      );

      const newArray = [...state.upcomingLiveEvents.list];
      newArray.splice(index, 1, action.payload);
      const fllterdLiveEvent = newArray.filter(
        (event) =>
          new Date(addMinutes(new Date(event.scheduledAt), event.duration)) >=
          new Date()
      );
      fllterdLiveEvent.sort(
        (a, b) => new Date(a.scheduledAt) - new Date(b.scheduledAt)
      );

      state.upcomingLiveEvents = {
        ...state.upcomingLiveEvents,
        list: fllterdLiveEvent,
      };
    },
    removeUpcomingLiveEvent: (state, action) => {
      const newArray = [...state.upcomingLiveEvents.list];
      const { id } = action.payload;
      const fllterLiveEvent = newArray.filter((event) => event.id !== id);
      state.upcomingLiveEvents = {
        ...state.upcomingLiveEvents,
        list: fllterLiveEvent,
      };
    },
    updateBlockedUsers: (state, action) => {
      state.blockedUsers = action.payload;
    },
    videoRecorderError: (state) => {
      state.videoRecorderError = true;
    },
    editLiveEvent: (state, action) => {
      state.editLiveEvent = action.payload;
    },
    resetEditLiveEvent: (state) => {
      state.editLiveEvent = null;
    },
    addChatActivity: (state, action) => {
      state.chatActivity.push(action.payload);
    },
    addLikeActivity: (state, action) => {
      state.likesActivity.push(action.payload);
    },
    addFeedActivity: (state, action) => {
      state.feedActivity.push(action.payload);
    },
    updateLiveEventStatus: (state, action) => {
      state.liveEventDetails = {
        ...state.liveEventDetails,
        ...action.payload,
      };
    },
    updateLiveEventDetails: (state, action) => {
      state.liveEventDetails = {
        ...state.liveEventDetails,
        ...action.payload,
      };
    },
    resetLiveData: (state) => {
      state.selectedLiveEvent = null;
      state.liveEventDetails = null;
      state.chatActivity = [];
      state.feedActivity = [];
      state.attendeesList = [];
      state.onlineAttendeesCount = 0;
    },
    updateAttendees: (state, action) => {
      state.attendeesList = action.payload;
    },
    updateAttendeesCount: (state, action) => {
      state.onlineAttendeesCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUpcomingLiveSession.pending, (state) => {
        state.upcomingLiveEvents.isLoading = true;
      })
      .addCase(getPastLiveSession.pending, (state) => {
        state.pastLiveEvents.isLoading = true;
      })
      .addCase(getFullLiveEventSchedule.pending, (state) => {
        state.fullLiveEventSchedule.isLoading = true;
      })
      .addCase(updateLiveEventChecklist.fulfilled, (state, action) => {
        state.liveEventDetails = {
          ...state.liveEventDetails,
          ...action.payload,
        };
      })
      .addCase(getLiveData.fulfilled, (state, action) => {
        const { activity, id: liveEventId } = action.payload || {};
        const tempChatList = [];
        const tempFeedList = [];
        if (activity) {
          const activityList = Object.values(activity);
          activityList.forEach((activityItem) => {
            const id = uuidv4();
            const tempActivityItem = { ...activityItem, id };
            if (activityItem.type === liveEventActivity.COMMENT) {
              tempChatList.push(tempActivityItem);
            } else if (activityItem.type === liveEventActivity.DONATE) {
              if (activityItem.amount) {
                tempFeedList.push(tempActivityItem);
              } else {
                notifyHandledError(null, {
                  message: 'Received donation without amount',
                  screen: 'liveStore',
                  liveEventId,
                  date: activityItem.date,
                  productId: activityItem.productId,
                  userId: activityItem.userId,
                  coachId: activityItem.coachId,
                });
              }
            } else if (
              activityItem.type === liveEventActivity.FOLLOW ||
              activityItem.type === liveEventActivity.COACHING
            ) {
              tempFeedList.push(tempActivityItem);
            }
          });
        }

        state.liveEventDetails = action.payload;
        state.chatActivity = tempChatList;
        state.feedActivity = tempFeedList;
      })
      .addCase(getUpcomingLiveSession.fulfilled, (state, action) => {
        const liveEventList = [...action.payload];
        const fllterdLiveEvent = liveEventList.filter(
          (event) =>
            new Date(addMinutes(new Date(event.scheduledAt), event.duration)) >=
            new Date()
        );
        fllterdLiveEvent.sort(
          (a, b) => new Date(a.scheduledAt) - new Date(b.scheduledAt)
        );

        state.upcomingLiveEvents = {
          ...state.upcomingLiveEvents,
          isLoading: false,
          isFetched: true,
          error: false,
          list: fllterdLiveEvent,
        };
      })
      .addCase(getPastLiveSession.fulfilled, (state, action) => {
        state.coachLiveSummary = action.payload.liveSummary;

        const liveEventList = [...action.payload.analytics];
        liveEventList.sort(
          (a, b) => new Date(b.scheduledAt) - new Date(a.scheduledAt)
        );
        state.pastLiveEvents = {
          ...state.pastLiveEvents,
          isLoading: false,
          isFetched: true,
          error: false,
          list: liveEventList,
        };
      })
      .addCase(getFullLiveEventSchedule.fulfilled, (state, action) => {
        const liveEventList = [...action.payload];
        const fllterdLiveEvent = liveEventList.filter(
          (event) => new Date(event.scheduledAt) >= new Date()
        );
        fllterdLiveEvent.sort(
          (a, b) => new Date(a.scheduledAt) - new Date(b.scheduledAt)
        );

        state.fullLiveEventSchedule = {
          ...state.fullLiveEventSchedule,
          isLoading: false,
          isFetched: true,
          error: false,
          list: fllterdLiveEvent,
        };
      })

      .addCase(getUpcomingLiveSession.rejected, (state) => {
        state.upcomingLiveEvents.error = true;
        state.upcomingLiveEvents.isLoading = false;
      })
      .addCase(getPastLiveSession.rejected, (state) => {
        state.pastLiveEvents.error = true;
        state.pastLiveEvents.isLoading = false;
      })
      .addCase(getFullLiveEventSchedule.rejected, (state) => {
        state.upcomingLiveEvents.error = true;
        state.upcomingLiveEvents.isLoading = false;
      });
  },
});
/* eslint-disable no-param-reassign */

export const {
  setSelectedLiveData,
  addNewUpcomingLive,
  updateLiveEvent,
  removeUpcomingLiveEvent,
  videoRecorderError,
  editLiveEvent,
  resetEditLiveEvent,
  addChatActivity,
  addLikeActivity,
  addFeedActivity,
  updateLiveEventStatus,
  updateLiveEventDetails,
  resetLiveData,
  updateAttendees,
  updateAttendeesCount,
  updateBlockedUsers,
} = liveSlice.actions;

export default liveSlice.reducer;
