import axios from 'axios';

import Auth from '../services/FirebaseAuth';

import config from '../config';
import { notifyAPIError } from '../services/ErrorMonitoring';

async function listJournals(userId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/notes/list`,
      data: { userId },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching Journal list' });
    return null;
  }
}

async function createJournal(journalData) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/notes`,
      data: journalData,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error adding Journal' });
    return null;
  }
}

async function deleteJournal(journalId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/coaching/notes/${journalId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error deleting Journal' });
    return null;
  }
}

async function updateJournal(journalData, journalId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/coaching/notes/${journalId}`,
      data: journalData,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error updating Journal' });
    return null;
  }
}

export { listJournals, createJournal, deleteJournal, updateJournal };
