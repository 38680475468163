import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { listCoaches } from '../../models/coach';
import Logger from '../../services/Logger';

export const getAllCoaches = createAsyncThunk(
  'coaches/getAllCoaches',
  async () => {
    Logger.debug('Fetching coaches');

    const result = await listCoaches();

    return result;
  }
);

const initialState = {
  isLoading: false,
  allCoaches: null,
};

/* eslint-disable no-param-reassign */
export const coachesSlice = createSlice({
  name: 'coaches',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCoaches.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getAllCoaches.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allCoaches = action.payload;
        state.error = null;
      });
  },
});
/* eslint-disable no-param-reassign */

export default coachesSlice.reducer;
