import React from 'react';
import { appWithTranslation } from 'next-i18next';
import '@aurahealth/web-design-system/dist/assets/css/ui-video-seek-slider.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { ErrorBoundary } from '../services/Bugsnag';
import { reduxWrapper } from '../store';

// styles
import '../styles/global.scss';
import 'nprogress/nprogress.css';

const App = ({ Component, ...rest }) => {
  const { props, store } = reduxWrapper.useWrappedStore(rest);

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Component {...props.pageProps} />
        <ToastContainer />
      </ErrorBoundary>
    </Provider>
  );
};

export default appWithTranslation(App);
