import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { differenceInMinutes } from 'date-fns';

import FirebaseDatabase from '../services/FirebaseDatabase';
import Auth from '../services/FirebaseAuth';
import config from '../config';
import BugsnagClient from '../services/Bugsnag';
import {
  notifyAPIError,
  notifyHandledError,
} from '../services/ErrorMonitoring';
import { canShowNewReferral } from './coach';
import { generateExternalUrlQueryPath } from '../utils';
import referralConstants from '../utils/constants/referralConstants';
import { getUrlLocale } from './locale';

let liveEventActivityListenerRef = null;
let liveEventAttendeesListenerRef = null;
let liveEventOnlineAttendeesCountListenerRef = null;
let liveEventStatusListener = null;
let liveEventBlockedUserListener = null;

async function setupLiveActivityListener(liveId, onLiveDataReceived) {
  try {
    if (liveEventActivityListenerRef) {
      FirebaseDatabase.off(liveEventActivityListenerRef);
    }
    liveEventActivityListenerRef = FirebaseDatabase.getRef(
      `/liveEvents/${liveId}/activity`
    );
    FirebaseDatabase.onChildAdded(liveEventActivityListenerRef, (snapshot) => {
      const liveData = snapshot.val();
      if (liveData) {
        const id = uuidv4();
        liveData.activityId = snapshot.key;
        liveData.id = id;
        onLiveDataReceived(liveData);
      } else {
        BugsnagClient.leaveBreadcrumb(
          `Received null live event activity in listener ${liveId}`
        );
      }
    });
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching live event data',
      liveId,
    });
  }
}

async function clearLiveActivityListener() {
  try {
    if (liveEventActivityListenerRef) {
      FirebaseDatabase.off(liveEventActivityListenerRef);
    }
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while clearing live activity listener',
    });
  }
}

async function setupLiveAttendeesListener(liveId, onLiveDataReceived) {
  try {
    if (liveEventAttendeesListenerRef) {
      FirebaseDatabase.off(liveEventAttendeesListenerRef);
    }
    liveEventAttendeesListenerRef = FirebaseDatabase.getRef(
      `/liveEvents/${liveId}/attendees`
    );
    FirebaseDatabase.onValue(liveEventAttendeesListenerRef, (snapshot) => {
      const liveData = snapshot.val();
      if (liveData) {
        onLiveDataReceived(liveData);
      } else {
        BugsnagClient.leaveBreadcrumb(
          `Received null live event attendees in listener ${liveId}`
        );
      }
    });
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching live attendees data',
      liveId,
    });
  }
}

async function setupLiveAttendeesCountListener(liveId, onLiveDataReceived) {
  try {
    if (liveEventOnlineAttendeesCountListenerRef) {
      FirebaseDatabase.off(liveEventOnlineAttendeesCountListenerRef);
      liveEventOnlineAttendeesCountListenerRef = null;
    }
    liveEventOnlineAttendeesCountListenerRef = FirebaseDatabase.getRef(
      `/liveEvents/${liveId}/analytics/onlineAttendeesCount`
    );
    FirebaseDatabase.onValue(
      liveEventOnlineAttendeesCountListenerRef,
      (snapshot) => {
        const liveData = snapshot.val();
        if (liveData >= 0) {
          onLiveDataReceived(liveData);
        } else {
          BugsnagClient.leaveBreadcrumb(
            `Received null live event online attendees count in listener ${liveId}`
          );
        }
      }
    );
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching live attendees count data',
      liveId,
    });
  }
}

async function setupLiveEventStatusListener(liveId, onLiveDataReceived) {
  try {
    if (liveEventStatusListener) {
      FirebaseDatabase.off(liveEventStatusListener);
      liveEventStatusListener = null;
    }
    liveEventStatusListener = FirebaseDatabase.getRef(
      `/liveEvents/${liveId}/status`
    );
    FirebaseDatabase.onValue(liveEventStatusListener, (snapshot) => {
      const status = snapshot.val();
      onLiveDataReceived(status);
    });
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching live event Firebase status data',
      liveId,
    });
  }
}

async function clearLiveEventStatusListener() {
  try {
    if (liveEventStatusListener) {
      FirebaseDatabase.off(liveEventStatusListener);
    }
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while clearing live attendees listener',
    });
  }
}

async function clearLiveAttendeesListener() {
  try {
    if (liveEventAttendeesListenerRef) {
      FirebaseDatabase.off(liveEventAttendeesListenerRef);
    }
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while clearing live attendees listener',
    });
  }
}

async function clearLiveAttendeesCountListener() {
  try {
    if (liveEventOnlineAttendeesCountListenerRef) {
      FirebaseDatabase.off(liveEventOnlineAttendeesCountListenerRef);
      liveEventOnlineAttendeesCountListenerRef = null;
    }
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while clearing live attendees count listener',
    });
  }
}

async function setupBlockedUsersListener(liveId, onBlockedUserDataReceived) {
  try {
    if (liveEventBlockedUserListener) {
      FirebaseDatabase.off(liveEventBlockedUserListener);
      liveEventBlockedUserListener = null;
    }
    liveEventBlockedUserListener = FirebaseDatabase.getRef(
      `/liveEvents/${liveId}/blockedUsers`
    );
    FirebaseDatabase.onValue(liveEventBlockedUserListener, (snapshot) => {
      const blockedUserData = snapshot.val();
      if (blockedUserData) {
        onBlockedUserDataReceived(blockedUserData);
      } else {
        onBlockedUserDataReceived([]);
        BugsnagClient.leaveBreadcrumb(
          `Received null in blocked users listener ${liveId}`
        );
      }
    });
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching blocked users',
      liveId,
    });
  }
}

async function clearBlockedUsersListener() {
  try {
    if (liveEventActivityListenerRef) {
      FirebaseDatabase.off(liveEventBlockedUserListener);
    }
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while clearing live blocked users listener',
    });
  }
}

async function getLiveSession(liveId) {
  try {
    const liveData = await FirebaseDatabase.getValue(`/liveEvents/${liveId}`);

    if (liveData) {
      liveData.id = liveId;
      return liveData;
    }
  } catch (error) {
    notifyHandledError(error, { message: 'Error fetching live event', liveId });
  }
  return null;
}

async function updateLiveEventActivity(liveId, activity) {
  try {
    await FirebaseDatabase.pushValue(
      `/liveEvents/${liveId}/activity`,
      activity
    );
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while updating live event activity',
      liveId,
    });
  }
}

async function updateBlockedUsers(liveId, userId, value) {
  try {
    const data = {};
    data[userId] = value;
    await FirebaseDatabase.updateValue(
      `liveEvents/${liveId}/blockedUsers`,
      data
    );
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while updating blocked users',
      liveId,
      userId,
    });
  }
}

async function updateLiveCheckList(liveId, key, value) {
  const checkList = {};
  checkList[key] = value;
  await FirebaseDatabase.updateValue(
    `/liveEvents/${liveId}/liveCheckList`,
    checkList
  );
}

async function addLiveEvent(data) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/live`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while adding new live event' });
    return null;
  }
}

async function updateLiveEvent(liveId, data) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/scheduling/live/${liveId}`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while updating live event' });
    return null;
  }
}

async function deleteLiveEvent(liveId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/scheduling/live/${liveId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while delete live event' });
    return null;
  }
}

async function getLiveEventList({
  coachId,
  anyStatus,
  start,
  includeTest = false,
}) {
  const authToken = await Auth.getUserAuthToken();
  const data = { coachId, anyStatus, start, includeTest };
  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/live/list`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while adding new live event' });
    return null;
  }
}
async function getLiveEventAnalytics({ coachId, liveId }) {
  try {
    const liveAnalytics = await FirebaseDatabase.getValue(
      `coachLiveEventsAnalytics/${coachId}/${liveId}`
    );

    return liveAnalytics;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching live event analytics',
      liveId,
    });
  }
  return null;
}

async function getLiveEventAnalyticsList(coachId) {
  try {
    const liveAnalytics = await FirebaseDatabase.getValue(
      `coachLiveEventsAnalytics/${coachId}`
    );
    return liveAnalytics;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching live event analytics list',
      coachId,
    });
  }
  return null;
}

async function getCoachLiveEventSummary(coachId) {
  try {
    const liveAnalytics = await FirebaseDatabase.getValue(
      `/coachLiveEventsSummaryAnalytics/${coachId}`
    );
    return liveAnalytics;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching coach live summary',
      coachId,
    });
  }
  return null;
}

async function updateLiveDetails({ liveId, data }) {
  try {
    const liveData = { ...data };

    if (liveData.id) {
      delete liveData.id;
    }

    await FirebaseDatabase.updateValue(`liveEvents/${liveId}`, liveData);
    return true;
  } catch (error) {
    notifyHandledError(error, { message: 'Error updating live event details' });
    return null;
  }
}

async function getLiveEventToken(liveId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/live/${liveId}/accessToken`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while getting live event token' });
    return null;
  }
}

async function updateLiveEventStatus(liveId, status) {
  const authToken = await Auth.getUserAuthToken();
  const data = { status };
  try {
    const response = await fetch(
      `${config.api.auraServices}/scheduling/live/${liveId}/status`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        keepalive: true,
      }
    );

    if (response && response.status === 200) {
      const responseDate = await response.json();
      return responseDate;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while updating live event status',
    });
    return null;
  }
}

async function setSchedulingCalender(
  calendarEmail,
  calendarType,
  calendarToken
) {
  const authToken = await Auth.getUserAuthToken();
  const data = { calendarEmail, calendarType, calendarToken };
  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/calendars/token`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while setting calender' });
    return null;
  }
}

function getLiveEventLink(liveEvent, coachDetails = {}, user = {}) {
  const { id: liveEventId } = liveEvent || {};
  if (!liveEvent) return null;
  const { referralCode } = user || {};
  if (!coachDetails) return null;
  const { slug, locale } = coachDetails;
  if (canShowNewReferral(coachDetails) && referralCode)
    return `${config.userWeb}/${getUrlLocale(
      locale
    )}refer/${referralCode}/live/${liveEventId}`;
  const path = `${config.userWeb}/coaches/${slug}/live`;
  const query = {
    liveEventId,
    referralCode,
    referralType: referralConstants.TYPE_COACH_BM_COACHING,
    utm_source: referralConstants.UTM_SOURCE_COACH_WEB,
  };
  return generateExternalUrlQueryPath(path, query);
}

async function createTempStream(liveId, coachId) {
  const data = {
    title: 'Demo stream',
    contentType: 'mindfulness',
    duration: 10,
    scheduledAt: new Date().toISOString(),
    description: 'Demo stream',
    topics: {
      happiness: true,
    },
    status: 'scheduled',
    coachId,
  };

  try {
    await FirebaseDatabase.updateValue(`liveEvents/${liveId}`, data);
    return { id: liveId, ...data };
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while creating demo stream',
      liveId,
    });
  }
  return null;
}

async function deleteTempStream(liveId) {
  try {
    if (!liveId || !liveId.includes('demo')) return null;
    await FirebaseDatabase.removeValue(`liveEvents/${liveId}`);
    return true;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while deleting demo stream',
      liveId,
    });
  }
  return null;
}

async function getLiveEvent(liveId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/live/${liveId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while getting live event' });
    return null;
  }
}

function isEditAllowed(liveEvent) {
  if (!liveEvent) return false;
  const timeRemainForLive = differenceInMinutes(
    new Date(liveEvent.scheduledAt),
    new Date()
  );
  if (timeRemainForLive < 10) {
    return false;
  }
  return true;
}

async function getLiveEventReviews(liveEventId) {
  const authToken = await Auth.getUserAuthToken();

  const data = {
    liveEventId,
  };

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/reviews/list`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while getting live event review' });
    return null;
  }
}

async function getLiveVideoRecordingUrl(liveEventId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/live/${liveEventId}/recording/url`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while getting live video recording Url',
    });
    return null;
  }
}

export {
  setupLiveActivityListener,
  setupLiveAttendeesListener,
  setupLiveAttendeesCountListener,
  setupLiveEventStatusListener,
  getLiveSession,
  updateLiveEventActivity,
  updateLiveCheckList,
  addLiveEvent,
  updateLiveEvent,
  getLiveEventList,
  getLiveEventAnalytics,
  getLiveEventAnalyticsList,
  getCoachLiveEventSummary,
  updateLiveDetails,
  getLiveEventToken,
  updateLiveEventStatus,
  clearLiveActivityListener,
  clearLiveAttendeesListener,
  clearLiveAttendeesCountListener,
  clearLiveEventStatusListener,
  getLiveEventLink,
  setSchedulingCalender,
  createTempStream,
  deleteTempStream,
  getLiveEvent,
  deleteLiveEvent,
  isEditAllowed,
  updateBlockedUsers,
  setupBlockedUsersListener,
  clearBlockedUsersListener,
  getLiveEventReviews,
  getLiveVideoRecordingUrl,
};
