import Mixpanel from './Mixpanel';
import Logger from './Logger';
import BugsnagClient from './Bugsnag';

import appConstants from '../utils/constants/app';
import LocalStorage from './LocalStorage';
import { roles } from '../utils/constants/roles';

function init() {
  Mixpanel.init();
}

function initMixpanel() {
  Mixpanel.init();
}

function identifyUser(user, isNewUser) {
  if (user && user.id) {
    const {
      id,
      coachOnboardingType,
      premium,
      premiumTrial,
      givenName,
      email,
      role,
    } = user;
    Mixpanel.identify(user.id, isNewUser);
    BugsnagClient.setUser(id, email, givenName);
    Logger.addUserData({ id, givenName, email, role });
    const superProperties = {
      UserID: id,
      CoachOnboardingType: coachOnboardingType,
      Name: givenName,
      Premium: premium || premiumTrial || false,
    };
    if (role === roles.ADMIN || role === roles.REVIEWER) {
      superProperties['Reviewer ID'] = id;
      superProperties['Reviewer Name'] = givenName;
    }
    setSuperProperties(superProperties);
  }
}

function identifyCoach(coach) {
  if (coach && coach.id) {
    const { id, name, countryCode, approved, tracks } = coach;
    Logger.addCoachData({ name, approved, tracks });
    const properties = {
      CoachID: id,
      'Coach Name': name,
      'Coach Country': countryCode,
    };
    setPeopleProperties(properties);
    setSuperProperties(properties);
  }
}

function signIn(user) {
  if (!user || !user.id) {
    return;
  }
  identifyUser(user);
  if (LocalStorage.isAvailable()) {
    const flag = LocalStorage.getItem(LocalStorage.KEYS.LOGIN_EVENT_SENT);
    const setTime = new Date(
      LocalStorage.getItem(LocalStorage.KEYS.LAST_LOGIN)
    );
    const thirtyMinutes = 60 * 30 * 1000;
    if (!flag || new Date() - setTime > thirtyMinutes) {
      track('Login');
      LocalStorage.setItem(LocalStorage.KEYS.LOGIN_EVENT_SENT, true);
      LocalStorage.setItem(
        LocalStorage.KEYS.LAST_LOGIN,
        new Date().toISOString()
      );
    }
  } else {
    track('Login');
  }
}

function setSuperProperties(data) {
  Mixpanel.setSuperProperties(data);
}

function setPeopleProperties(data) {
  Mixpanel.setPeopleProperties(data);
}

function track(event, data) {
  Mixpanel.track(event, {
    Time: new Date().toTimeString().slice(0, 2),
    Day: new Date().getDay(),
    'Sent from': appConstants.APP_NAME,
    Platform: appConstants.APP_NAME,
    ...data,
  });
  BugsnagClient.leaveBreadcrumb(event, data);
  Logger.debug(event, data);
}

function resetUser() {
  Mixpanel.signOut();
  BugsnagClient.setUser(null, null, null);
  if (LocalStorage.isAvailable()) {
    LocalStorage.removeItem(LocalStorage.KEYS.LOGIN_EVENT_SENT);
    LocalStorage.removeItem(LocalStorage.KEYS.LAST_LOGIN);
  }
}

export default {
  init,
  initMixpanel,
  identifyUser,
  identifyCoach,
  signIn,
  track,
  resetUser,
  setSuperProperties,
  setPeopleProperties,
};
