import axios from 'axios';
import FirebaseDatabase from '../services/FirebaseDatabase';
import Auth from '../services/FirebaseAuth';
import config from '../config';
import {
  notifyAPIError,
  notifyHandledError,
} from '../services/ErrorMonitoring';

async function getWeeklyAvailability() {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/coaches/weeklyAvailability`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching weekly availability' });
    return null;
  }
}

async function getTimeZones() {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/coaches/timezones`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching timeZone list' });
    return null;
  }
}

async function getCoachResource() {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/coaches/resource`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while fetching coach resource data',
    });
    return null;
  }
}

async function getCalenderAuthURL(email, emailType) {
  const authToken = await Auth.getUserAuthToken();
  const params = {
    calendarEmail: email,
    emailType,
    returnUrl: `${config.appDomain}/coaching`,
  };

  try {
    const options = {
      method: 'GET',
      params,
      url: `${config.api.auraServices}/scheduling/coaches/calendarAuthUrl`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while fetching coach resource data',
    });
    return null;
  }
}

async function setupConferenceLink(link) {
  try {
    const authToken = await Auth.getUserAuthToken();
    const data = {
      link,
    };
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/scheduling/coaches/conferenceLink`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while setup Conference Link',
    });
    return null;
  }
}

async function updateWeeklyAvailability(availabilityData) {
  const authToken = await Auth.getUserAuthToken();
  const availability = { ...availabilityData?.availability };
  Object.keys(availability).forEach((day) => {
    availability[day] = { ...availability[day].times };
  });
  const data = {
    timezoneId: availabilityData?.timezoneId,
    availability,
  };
  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/scheduling/coaches/weeklyAvailability`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching weekly availability' });
    return null;
  }
}

async function updateBookableStatus() {
  const authToken = await Auth.getUserAuthToken();

  const data = {
    bookable: true,
  };
  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/scheduling/coaches/bookable`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching bookable coaches' });
    return null;
  }
}

async function updateVideoCoachingApproved() {
  const authToken = await Auth.getUserAuthToken();

  const data = {
    videoCoachingApproved: true,
  };
  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/scheduling/coaches/videoCoachingApproved`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error updating video coaching approval',
    });
    return null;
  }
}

function isCalendarSynced(resourceData) {
  const {
    googleCalendarId,
    googleCalendarAuthorized,
    outlookCalendarId,
    outlookCalendarAuthorized,
  } = resourceData || {};
  return (
    (!!googleCalendarId && googleCalendarAuthorized) ||
    (!!outlookCalendarId && outlookCalendarAuthorized)
  );
}

async function getCoachingUserChecklist(coachId, userId) {
  try {
    const coachingUserChecklist = await FirebaseDatabase.getValue(
      `/coachingUserChecklist/${coachId}/${userId}`
    );

    if (coachingUserChecklist) {
      return coachingUserChecklist;
    }
    return null;
  } catch (error) {
    notifyHandledError(error, { message: 'Error fetching user checklist' });
    return null;
  }
}

async function updateCoachingUserChecklist(coachId, userId, key) {
  const checkList = {};
  checkList[key] = true;
  await FirebaseDatabase.updateValue(
    `/coachingUserChecklist/${coachId}/${userId}`,
    checkList
  );
}

async function getSessionPrices(coachId, sessionTypeId) {
  const authToken = await Auth.getUserAuthToken();

  const data = {
    coachId,
    sessionTypeId,
  };

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/coaches/sessionPrices/list/v2`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching session prices' });
    return null;
  }
}

async function getSessionType(sessionTypeId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/availability/sessionTypes/${sessionTypeId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching session types' });
    return null;
  }
}

async function updateSessionPrice(
  coachId,
  sessionTypeId,
  sessionPricesData,
  enable
) {
  const authToken = await Auth.getUserAuthToken();
  const sessionPrices = Object.keys(sessionPricesData).map((key) => {
    return {
      sessionTypeId,
      price:
        sessionPricesData[key].value === ''
          ? null
          : parseFloat(sessionPricesData[key].value) * 100,
      duration: sessionPricesData[key].duration,
      isActive: enable,
    };
  });
  const data = { sessionPrices };

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/coaches/${coachId}/sessionPrices`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching session price' });
    return null;
  }
}

async function updateMaxCoachingClients(maxCoachingClients) {
  const authToken = await Auth.getUserAuthToken();

  const data = {
    maxCoachingClients,
  };
  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/scheduling/coaches/maxCoachingClients`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error updating max coaching clients' });
    return null;
  }
}

async function cancelCoachingTrial(data) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/subscriptions/cancel`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while cancel coaching trial' });
    return null;
  }
}

async function getPackageTemplates() {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/packageTemplates/list`,
      data: {
        feature: 'video',
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while getting package templates' });
    return null;
  }
}

async function getCoachingPackages(userId, includeInactive = false) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/coachPackages/list`,
      data: {
        coachId: userId,
        includeInactive,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while getting coach packages' });
    return null;
  }
}

async function upsertCoachPackages({ coachId, coachPackages }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/coachPackages/upsert`,
      data: { coachId, coachPackagesList: coachPackages },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while upserting coach packages' });
    return null;
  }
}

async function getAvailablePrices() {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/products/list`,
      data: { provider: 'stripe', type: 'coach' },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while getting all available prices',
    });
    return null;
  }
}

async function getCoachingPackageItem(packageId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/coaching/coachPackages/${packageId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while getting a coaching package',
    });
    return null;
  }
}

async function getCoachingSubscriptionCredit({ subscriptionId, duration }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/coaching/subscriptions/credits/list`,
      data: {
        subscriptionId,
        duration,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while getting a coaching subscription credit',
    });
    return null;
  }
}

export {
  getTimeZones,
  getWeeklyAvailability,
  getCoachResource,
  getCalenderAuthURL,
  setupConferenceLink,
  updateWeeklyAvailability,
  updateBookableStatus,
  isCalendarSynced,
  getCoachingUserChecklist,
  updateCoachingUserChecklist,
  getSessionPrices,
  getSessionType,
  updateSessionPrice,
  updateMaxCoachingClients,
  updateVideoCoachingApproved,
  cancelCoachingTrial,
  getPackageTemplates,
  getCoachingPackages,
  upsertCoachPackages,
  getAvailablePrices,
  getCoachingPackageItem,
  getCoachingSubscriptionCredit,
};
