import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCourseList } from '../../models/courses';
import { notifyHandledError } from '../../services/ErrorMonitoring';

export const getCoursesList = createAsyncThunk(
  'courses/getCoursesList',
  async (data, { rejectWithValue }) => {
    try {
      const courseList = await getCourseList(data);

      return courseList;
    } catch (error) {
      notifyHandledError(error, { message: 'Unable get courses list' });
      return rejectWithValue();
    }
  }
);

const initialState = {
  list: [],
  isLoading: false,
  isFetched: false,
  error: false,
  selectedCourse: null,
};

/* eslint-disable no-param-reassign */
export const courseSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setSelectedCourse: (state, action) => {
      state.isLoading = false;
      state.selectedCourse = action.payload;
    },
    addNewCourse: (state, action) => {
      state.list = [action.payload, ...state.list];
    },
    updateCourseList: (state, action) => {
      const index = state.list.findIndex(
        (item) => item.id === action.payload.id
      );

      const newArray = [...state.list];
      newArray.splice(index, 1, action.payload);

      newArray.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      state.list = newArray;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoursesList.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.list = null;
      })

      .addCase(getCoursesList.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isLoading = false;
        state.isFetched = true;
        state.error = false;
      })

      .addCase(getCoursesList.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const { setSelectedCourse, updateCourseList, addNewCourse } =
  courseSlice.actions;

export default courseSlice.reducer;
