import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCountriesList } from '../../models/coach';
import Logger from '../../services/Logger';

export const getAllCountries = createAsyncThunk(
  'countries/getAllCountries',
  async () => {
    Logger.debug('Fetching countries');
    const result = await getCountriesList();
    Logger.debug('Fetched countries');
    return result;
  }
);

const initialState = {
  isLoading: false,
  allCountries: null,
};

/* eslint-disable no-param-reassign */
export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCountries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allCountries = action.payload;
        state.error = null;
      });
  },
});
/* eslint-disable no-param-reassign */

export default countriesSlice.reducer;
