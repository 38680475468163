const appConstants = Object.freeze({
  APP_NAME: 'CoachWeb',
  DEEPLINK_CHANNEL: 'coach_web',
  DEFAULT_LOCALE: 'en',
  MODE_PRODUCTION: 'PROD',
  ONBOARDING_TYPES: {
    COACHING: 'coaching',
    CONTENT: 'content',
  },
  COACH_TYPES: {
    AURA: 'aura',
    CELEBRITY: 'celebrity',
  },
});

module.exports = appConstants;
