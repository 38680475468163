import { createSlice } from '@reduxjs/toolkit';
import { filterApprovedTracks } from '../../models/meditation';

const initialState = {
  list: [],
  approvedList: [],
  loading: false,
  isFetched: false,
  selectedTrack: null,
};

/* eslint-disable no-param-reassign */
export const tracksSlice = createSlice({
  name: 'tracks',
  initialState,
  reducers: {
    setSelectedTrack: (state, action) => {
      state.isLoading = false;
      state.selectedTrack = action.payload;
    },
    startGetTracks: (state) => {
      state.loading = true;
    },
    successGetTracks: (state, action) => {
      state.list = action.payload;
      state.approvedList = filterApprovedTracks(action.payload);
      state.loading = false;
      state.isFetched = true;
    },
  },
});
/* eslint-disable no-param-reassign */

export const { startGetTracks, successGetTracks, setSelectedTrack } =
  tracksSlice.actions;

export default tracksSlice.reducer;
