import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as serviceModel from '../../models/service';
import Logger from '../../services/Logger';
import { notifyHandledError } from '../../services/ErrorMonitoring';

export const getServices = createAsyncThunk(
  'services/getServices',
  async (userId) => {
    try {
      const response = await serviceModel.listServices(userId);

      return response;
    } catch (error) {
      notifyHandledError(error, { message: 'Error fetching services' });
      return null;
    }
  }
);

export const deleteService = createAsyncThunk(
  'services/deleteService',
  async (serviceId) => {
    try {
      const response = await serviceModel.deleteService(serviceId);

      return response;
    } catch (error) {
      Logger.error('Error deleting service', { error });
      return null;
    }
  }
);

export const updateService = createAsyncThunk(
  'services/updateService',
  async ({ serviceId, data }) => {
    try {
      const response = await serviceModel.updateService({
        serviceId,
        data,
      });

      return response;
    } catch (error) {
      Logger.error('Error updating journal', { error });
      return null;
    }
  }
);

const initialState = {
  services: [],
  serviceSelected: null,
  loading: false,
  isFetched: false,
};

/* eslint-disable no-param-reassign */
export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    selectService: (state, action) => {
      state.serviceSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.loading = true;
      })

      .addCase(deleteService.fulfilled, (state, action) => {
        state.services = state.services.filter(
          (item) => item.id !== action.payload.id
        );
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.services = state.services.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.services = action.payload;
        state.loading = false;
        state.isFetched = true;
      });
  },
});
/* eslint-disable no-param-reassign */

export const { selectService } = servicesSlice.actions;

export default servicesSlice.reducer;
