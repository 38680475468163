import axios from 'axios';
import ContentServices from '../services/Content';
import Logger from '../services/Logger';
import BugsnagClient from '../services/Bugsnag';
import Auth from '../services/FirebaseAuth';
import FirebaseDatabase from '../services/FirebaseDatabase';
import contentConstants from '../utils/constants/content';
import {
  notifyAPIError,
  notifyHandledError,
} from '../services/ErrorMonitoring';
import { getChannel } from './channel';

import config from '../config';
import appConstants, { COACH_TYPES } from '../utils/constants/app';
import { roles } from '../utils/constants/roles';
import { generateExternalUrlQueryPath, isProdMode } from '../utils';
import referralConstants from '../utils/constants/referralConstants';
import { getUrlLocale } from './locale';

let coachListenerRef = null;

async function setupCoachListener(id, onCoachReceived) {
  try {
    if (coachListenerRef) {
      FirebaseDatabase.off(coachListenerRef);
    }
    coachListenerRef = FirebaseDatabase.getRef(`/coaches/${id}`);

    FirebaseDatabase.onValue(coachListenerRef, (snapshot) => {
      const coach = snapshot.val();
      if (coach) {
        coach.id = id;
        onCoachReceived(coach);
      } else {
        BugsnagClient.leaveBreadcrumb(`Received null coach in listener ${id}`);
      }
    });
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error setting up coach listener',
      coachId: id,
    });
  }
}

async function removeCoachListener() {
  try {
    if (coachListenerRef) {
      FirebaseDatabase.off(coachListenerRef);
    }
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error removing coach listener',
    });
  }
}

async function getCoach(id) {
  try {
    const coach = await FirebaseDatabase.getValue(`/coaches/${id}`);

    if (coach) {
      coach.id = id;
      return coach;
    }
    BugsnagClient.leaveBreadcrumb(`Received null coach ${id}`);
  } catch (error) {
    notifyHandledError(error, { message: 'Error fetching coach', coachId: id });
  }
  return null;
}

async function createCoach(data) {
  const authToken = await Auth.getUserAuthToken();

  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/coaches`,
    data,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };

  return axios(options);
}

async function updateCoach({ id, data }) {
  const authToken = await Auth.getUserAuthToken();

  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/coaches/${id}`,
    data,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };

  return axios(options);
}

async function getCoachTracks({ limit = 25, authorId, authorName } = {}) {
  if (!authorId && !authorName) {
    Logger.warn('Error fetching tracks: missing arguments');
    return null;
  }
  try {
    return ContentServices.getTracks({
      limit,
      useDayBucket: false,
      durationFilter: contentConstants.DURATION_KEYS.ALL,
      coaches: [authorId],
      category: contentConstants.CONTENT_CATEGORY.ALL,
    });
  } catch (error) {
    Logger.error('Error fetching coach tracks', { error, authorId });
  }
  return null;
}

async function listCoaches() {
  try {
    const value = await FirebaseDatabase.getValue(`/coaches`);

    if (value) {
      let coaches = Object.keys(value).map((coach) => {
        return {
          id: coach,
          ...value[coach],
        };
      });
      coaches.sort((a, b) => {
        if (!b.followersCount) return -1;
        return b.followersCount - a.followersCount;
      });
      coaches = coaches.filter((x) => {
        return !!x.name;
      });
      return coaches;
    }
  } catch (error) {
    notifyHandledError(error, { message: 'Error fetching coaches' });
  }
  return null;
}

async function getCoachChannels(ids) {
  Logger.debug(`coaching channels: list`, { ids });
  const promises = ids.map((id) => getChannel(id));
  return Promise.all(promises);
}

function hasCoachPublicProfile(coach) {
  if (!coach) return false;
  const { approved, videoCoachingApproved, bookable, slug } = coach;
  if (!slug || (!approved && !videoCoachingApproved && !bookable)) return false;
  return true;
}

function hasContentAccess(coach) {
  if (!coach) return false;
  const { approved, contentEnabledAt } = coach;
  return approved || contentEnabledAt;
}

function hasCoachingAccess(coach) {
  if (!coach) return false;
  const { coachingEnabledAt } = coach;
  return coachingEnabledAt;
}

function hasLiveAccess(coach) {
  if (!coach) return false;
  const { liveEnabledAt } = coach;
  return liveEnabledAt;
}

function hasCommunityAccess(coach) {
  if (!coach) return false;
  const { communityEnabledAt } = coach;
  return communityEnabledAt;
}

function hasCoursesAccess(coach) {
  if (!coach) return false;
  const { communityEnabledAt } = coach;
  return communityEnabledAt;
}

function hasEventAccess(coach) {
  if (!coach) return false;
  const { eventsEnabledAt } = coach;
  return eventsEnabledAt;
}

function canShowNewReferral(coach) {
  const { locale, newReferralsEnabledAt } = coach || {};
  if (!locale) return false;
  if (locale === appConstants.DEFAULT_LOCALE) return true;
  if (newReferralsEnabledAt) return true;
  return false;
}

function getPublicProfileUrl(coach, user = {}) {
  if (!coach) return null;
  const { slug } = coach;
  const { referralCode } = user || {};
  if (!slug) return null;
  const path = `${config.userWeb}/coaches/${slug}`;
  const query = {
    referralCode,
    referralType: referralConstants.TYPE_COACH_BM_COACHING,
    utm_source: referralConstants.UTM_SOURCE_COACH_WEB,
  };
  return generateExternalUrlQueryPath(path, query);
}

function getGuestPassUrl(coach, user = {}) {
  if (!user || !user.referralCode) return null;
  const { locale } = coach;
  const url = `https://aurahealth.io/${getUrlLocale(locale)}refer/${
    user.referralCode
  }/guest-pass`;
  return url;
}

function getGuestPassLocalBasedUrls(coach, user = {}) {
  if (!user || !user.referralCode) return null;
  const { approvedLocales = [appConstants.DEFAULT_LOCALE] } = coach;

  const guestPassUrls = approvedLocales.map((locale) => {
    return {
      locale,
      url: `https://aurahealth.io/${getUrlLocale(locale)}refer/${
        user.referralCode
      }/guest-pass`,
    };
  });

  return guestPassUrls;
}

function getCoachPhoto(coach, size) {
  const coachPhotoUrl = `https://firebasestorage.googleapis.com/v0/b/firebase-auratech16.appspot.com/o/auraTrackPic.png?alt=media&token=06b53bb7-8f44-4da0-a47a-82d94c030fbb`;
  if (!coach) {
    return coachPhotoUrl;
  }
  if (size && coach.profilePictureThumbs && coach.profilePictureThumbs[size]) {
    return coach.profilePictureThumbs[size];
  }
  if (size && contentConstants.THUMBS_SIZES[size] && coach.id && isProdMode()) {
    return `${config.api.auraServices}/assets/coach/picture/${coach.id}/${contentConstants.THUMBS_SIZES[size]}`;
  }
  if (coach.profilePicture) {
    return coach.profilePicture;
  }
  if (coach.photoUrl) {
    return coach.photoUrl;
  }
  if (coach.id) {
    return `${config.api.auraServices}/assets/coach/picture/${coach.id}`;
  }
  return coachPhotoUrl;
}

async function validateCoachCode(code) {
  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/codes/${code}/validate`,
    headers: {
      'Content-Type': 'application/json',
    },
    json: true,
  };

  return axios(options);
}

async function redeemCoachCode(code) {
  const authToken = await Auth.getUserAuthToken();

  const options = {
    method: 'POST',
    url: `${config.api.auraServices}/gifts/${code}/redeem`,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    json: true,
  };

  return axios(options);
}

async function updateFeatureEnabled({ coachId, feature, at = new Date() }) {
  try {
    const authToken = await Auth.getUserAuthToken();

    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/scheduling/coaches/featureEnabledAt`,
      data: {
        coachId,
        at,
        feature,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };

    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response?.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while updating coach feature enabled at',
    });
    return null;
  }
}

async function getCoachSchedulingDetails(coachId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/scheduling/coaches/${coachId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response?.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while fetching coach scheduling details',
    });
    return null;
  }
}

async function setupScheduling(coachId) {
  try {
    const authToken = await Auth.getUserAuthToken();
    const data = {
      coachId,
      sendInvite: true,
    };
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/scheduling/coaches/setup`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    return axios(options);
  } catch (error) {
    notifyAPIError(error, { message: 'Error while setup scheduling' });
    return null;
  }
}

async function updateCoachingFreeTrial(coachId, value) {
  try {
    const authToken = await Auth.getUserAuthToken();
    const data = {
      coachId,
      isAllowed: value,
    };
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/scheduling/coaches/allowFreeCoachingTrial`,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response?.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while updating coaching free trial status',
    });
    return null;
  }
}

async function updatePreviewCardBackground(coachId, value) {
  try {
    await FirebaseDatabase.updateValue(
      `/coaches/${coachId}/previewCardsBackground`,
      value
    );
    return { value };
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error while updating preview card background',
      coachId,
    });
    return null;
  }
}

async function getCoachCountry(countryCode) {
  try {
    const requestOptions = {
      method: 'GET',
      url: `${config.api.auraServices}/addresses/countries/${countryCode}`,
      headers: {
        'Content-Type': 'application/json',
      },
      json: true,
    };

    const response = await axios(requestOptions);

    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (err) {
    notifyAPIError(err, { message: 'Error fetching coach country' });
    return null;
  }
}

function canViewCoachingTab(coach) {
  if (!coach) return false;
  return !!coach.coachingEnabledAt;
}

async function getCountriesList() {
  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/addresses/countries`,
      headers: {
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.data) {
      return response.data;
    }
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching countries list' });
    return { error };
  }
  return { error: 'Countries Fetch Failed' };
}

function getCoachingLink(coach) {
  if (!coach) return null;
  const { slug, id } = coach;
  return `${config.userWeb}/coaching/${slug || id}`;
}

function getVideoCoachingLink(coach, excludeDiscovery) {
  if (!coach || !coach.slug) return null;
  const { slug } = coach;
  const path = `${config.userWeb}/coaches/${slug}/video-coaching`;
  const query = {
    excludeDiscovery,
  };
  return generateExternalUrlQueryPath(path, query);
}

function getCoachFirstName(coach) {
  return coach?.name?.split(' ')[0] || 'Aura';
}

async function addCoachingIntroVideo({ videoData }) {
  try {
    const authToken = await Auth.getUserAuthToken();
    const options = {
      method: 'put',
      url: `${config.api.auraServices}/scheduling/coaches/coachingIntroVideo`,
      data: videoData,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response?.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching countries list' });
    return null;
  }
}

async function removeCoachingIntroVideo() {
  try {
    const authToken = await Auth.getUserAuthToken();
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/scheduling/coaches/coachingIntroVideo`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response?.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while deleting coaching intro video',
    });
    return null;
  }
}

function isCelebrity(coach) {
  return coach?.type === COACH_TYPES.CELEBRITY;
}

function isAdmin(user) {
  return user && user.role && user.role === roles.ADMIN;
}

async function setApprovedLocales(coachId, approvedLocales) {
  if (!coachId || !approvedLocales) {
    return null;
  }
  try {
    const approveLocalResponse = await FirebaseDatabase.setValue(
      `/coaches/${coachId}/approvedLocales`,
      approvedLocales
    );

    return approveLocalResponse;
  } catch (error) {
    Logger.error('Error while setting approved locales', {
      error,
    });
    BugsnagClient.notify(error);
    return null;
  }
}

export {
  setApprovedLocales,
  setupCoachListener,
  removeCoachListener,
  getCoach,
  getCoachingLink,
  getVideoCoachingLink,
  canShowNewReferral,
  createCoach,
  updateCoach,
  listCoaches,
  getCoachChannels,
  hasCoachPublicProfile,
  hasContentAccess,
  hasCommunityAccess,
  hasCoachingAccess,
  hasCoursesAccess,
  hasEventAccess,
  hasLiveAccess,
  getPublicProfileUrl,
  getGuestPassUrl,
  getGuestPassLocalBasedUrls,
  getCoachPhoto,
  getCoachTracks,
  validateCoachCode,
  redeemCoachCode,
  updateFeatureEnabled,
  getCoachSchedulingDetails,
  setupScheduling,
  updateCoachingFreeTrial,
  getCoachCountry,
  canViewCoachingTab,
  updatePreviewCardBackground,
  getCountriesList,
  getCoachFirstName,
  addCoachingIntroVideo,
  removeCoachingIntroVideo,
  isCelebrity,
  isAdmin,
};
