const referralConstants = Object.freeze({
  // Types
  TYPE_USER_SUBSCRIPTION_7TRIAL: 'user_subscription_7trial',
  COACH_SUBSCRIPTION_30TRIAL: 'coach_subscription_30trial',
  TYPE_COACH_BM_COACHING: 'coach_bm_coaching',

  SOURCE_AMBASSADOR: 'ambassador',
  CHANNEL_EMAIL: 'Email',
  CHANNEL_COPY_LINK: 'CopyLink',

  // utm source
  UTM_SOURCE_COACH_WEB: 'coachweb',

  MAX_MONTHLY_INVITES: 5,
  // Status
  STATUS_PENDING: 'pending',
  STATUS_QUALIFIED: 'qualified',
  STATUS_APPROVED: 'approved',
  STATUS_CREDITED: 'credited',
  STATUS_CANCELLED: 'cancelled',
  GUESTPASS_DISPLAY_STATUS: {
    pending: 'Started Guest Pass',
    qualified: 'Started Guest Pass',
    credited: 'Successful Referral',
    cancelled: 'Cancelled Guest Pass',
  },
});

export default referralConstants;
