import axios from 'axios';
import Auth from '../services/FirebaseAuth';
import config from '../config';
import { notifyAPIError } from '../services/ErrorMonitoring';

async function listCommunities(data) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/communities/list`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
      data,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while fetching community list',
    });
    return null;
  }
}

async function getCommunityById(communityId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/communities/${communityId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while fetching community by id',
      communityId,
    });
    return null;
  }
}

async function createCommunity({ data }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/communities`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while creating community',
    });
    return null;
  }
}

function getCommunityPublicUrl(coach, community) {
  if (!community || !coach) return null;
  const { slug } = community;
  const { slug: coachSlug } = coach;
  // if (!slug || !coachSlug) return null;
  return `${config.userWeb}/coaches/${coachSlug}/communities/${slug}`;
}

async function editComunity({ communityId, data }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/communities/${communityId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while editing community' });
    return null;
  }
}

export {
  listCommunities,
  getCommunityById,
  createCommunity,
  getCommunityPublicUrl,
  editComunity,
};
