import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import TODO_CHECKLIST_ITEMS from '../../data/todoCheckList.json';
import TODO_CHECKLIST_ITEMS_TEST_USER from '../../data/todoCheckListTestUser.json';
import config from '../../config';

import {
  getUser,
  getUserCoachingSubscription,
  hasUserCoachingAppointment,
  isUserCoachingSubscriber,
} from '../../models/user';
import {
  getCoachingUserChecklist,
  cancelCoachingTrial,
  updateCoachingUserChecklist,
} from '../../models/coaching';

import {
  createCannedResponse,
  deleteCannedResponse,
  updateCannedResponseItem,
} from '../../models/cannedResponse';
import Analytics from '../../services/Analytics';
import { getScheduledAppointmentList } from '../../models/appointment';
import { notifyHandledError } from '../../services/ErrorMonitoring';

export const setSelectedUser = createAsyncThunk(
  'chats/setSelectedUser',
  async ({ userDetails, userId }, { getState, rejectWithValue }) => {
    try {
      const { id: coachId } = getState().auth.user;
      const userResponse = !userDetails ? await getUser(userId) : userDetails;
      const subscriptionResponse = !userDetails
        ? await getUserCoachingSubscription(userId, coachId)
        : userDetails?.subscription;
      const todoCheckList = await getCoachingUserChecklist(coachId, userId);
      const appointmentList = await getScheduledAppointmentList(
        coachId,
        userId,
        ['free-discovery']
      );
      Analytics.track('Set Selected Client', { ClientID: userId });
      return {
        user: {
          ...userResponse,
          todoCheckList: !todoCheckList ? {} : todoCheckList,
          subscription: subscriptionResponse,
          appointmentList: !appointmentList ? [] : appointmentList,
        },
        isActiveSubscriber:
          isUserCoachingSubscriber(userDetails, coachId) ||
          hasUserCoachingAppointment(userDetails, coachId, appointmentList),
      };
    } catch (error) {
      notifyHandledError(error, { message: 'Error fetching user details' });
      return rejectWithValue({ error });
    }
  }
);

export const updateCoachingUsersChecklist = createAsyncThunk(
  'chats/updateCoachingUserChecklist',
  async (key, { getState, rejectWithValue }) => {
    try {
      const {
        chats: { user },
        auth: {
          user: { id: coachId },
        },
      } = getState();
      const { id: userId, todoCheckList } = user || {};
      const updatedChecklist = { ...todoCheckList };
      updatedChecklist[key] = true;
      await updateCoachingUserChecklist(coachId, userId, key);
      Analytics.track('Completed todo checklist', { checkList: key });

      return updatedChecklist;
    } catch (error) {
      notifyHandledError(error, {
        message: 'Error while updating todo checklist',
      });
      return rejectWithValue({ error });
    }
  }
);

export const addNewCannedResponse = createAsyncThunk(
  'chats/addNewCannedResponse',
  async ({ name, message }, { rejectWithValue, getState }) => {
    try {
      const {
        chats: { cannedResponses },
      } = getState();
      const newCannedMessage = await createCannedResponse({
        name,
        message,
      });
      if (newCannedMessage) {
        Analytics.track('Canned Response Created', {
          Name: name,
          CannedResponseID: newCannedMessage.id,
        });

        return [...cannedResponses, newCannedMessage];
      }
      return null;
    } catch (error) {
      notifyHandledError(error, { message: 'Error fetching user details' });
      return rejectWithValue({ error });
    }
  }
);

export const updateCannedResponse = createAsyncThunk(
  'chats/updateCannedResponse',
  async (updatedCannedMessage, { getState, rejectWithValue }) => {
    try {
      const {
        chats: { cannedResponses },
      } = getState();
      const updatedCannedMessageItem = await updateCannedResponseItem(
        updatedCannedMessage
      );
      if (updatedCannedMessageItem) {
        Analytics.track('Canned Response Updated', {
          CannedResponseID: updatedCannedMessageItem.id,
        });
        const messageIndex = cannedResponses.findIndex(
          (item) => item.id === updatedCannedMessageItem.id
        );
        cannedResponses[messageIndex] = updatedCannedMessageItem;
        return cannedResponses;
      }
      return null;
    } catch (error) {
      notifyHandledError(error, {
        message: 'Error updating canned response item',
      });
      return rejectWithValue({ error });
    }
  }
);

export const deleteCannedResponseItem = createAsyncThunk(
  'chats/deleteCannedResponseItem',
  async (itemId, { getState, rejectWithValue }) => {
    try {
      const {
        chats: { cannedResponses },
      } = getState();
      const deletedCannedMessage = await deleteCannedResponse(itemId);
      if (deletedCannedMessage) {
        Analytics.track('Canned Response Deleted', {
          CannedResponseID: itemId,
        });
        return cannedResponses.filter(
          (item) => item.id !== deletedCannedMessage.id
        );
      }
      return null;
    } catch (error) {
      notifyHandledError(error, {
        message: 'Error deleting canned response item',
      });
      return rejectWithValue({ error });
    }
  }
);

export const cancelCoachingTrials = createAsyncThunk(
  'chats/cancelCoachingTrials',
  async ({ conversationId, data }) => {
    try {
      const response = await cancelCoachingTrial(data);

      if (response) {
        return {
          conversationId,
          subscription: {
            ...response,
            cancelledAt: new Date().toISOString(),
          },
        };
      }
      return null;
    } catch (error) {
      notifyHandledError(error, {
        message: 'Error while cancel coaching trial',
      });
      return null;
    }
  }
);

export const resetSelectedChat = createAction('chats/resetSelectedChat');

const initialState = {
  conversationList: [],
  selected: null,
  user: null,
  selectedMessage: null,
  isActiveSubscriber: true,
  selectedFile: null,
  cannedResponses: [],
  isTodoCompleted: false,
};

const checkTodoCompleted = (userId, todoList) => {
  let todoCheckListItem = TODO_CHECKLIST_ITEMS;
  if (userId === config.auraCoachingTestUser)
    todoCheckListItem =
      TODO_CHECKLIST_ITEMS_TEST_USER.concat(TODO_CHECKLIST_ITEMS);

  return todoCheckListItem.every((step) =>
    step.items.every((item) => !!todoList[item.key])
  );
};

/* eslint-disable no-param-reassign */
export const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setSelectedChat: (state, action) => {
      state.selected = action.payload;
    },
    setSelectedMessage: (state, action) => {
      const data = action.payload;
      let messageData = { ...data, sender: data.sender };
      if (data.url) messageData = { ...messageData, url: data.url };
      state.selectedMessage = { ...messageData };
    },
    resetSelectedMessage: (state) => {
      state.selectedMessage = null;
    },
    addFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    removeFile: (state) => {
      state.selectedFile = null;
    },
    setCannedResponses: (state, action) => {
      state.cannedResponses = action.payload;
    },
    setConversationList: (state, action) => {
      state.conversationList = action.payload;
    },
    updateConversationSubscription: (state, action) => {
      const index = state.conversationList.findIndex(
        (conversation) => conversation.url !== action.conversationId
      );
      const newArray = [...state.conversationList];
      const { userDetails } = state.conversationList[index];
      userDetails.subscription = {
        ...userDetails.subscription,
        ...action.payload.subscription,
      };
      newArray[index].userDetails = userDetails;

      state.conversationList = newArray;
      state.user = {
        ...state.user,
        subscription: {
          ...state.user.subscription,
          ...action.payload.subscription,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetSelectedChat, (state) => {
        state.selected = null;
        state.user = null;
        state.selectedMessage = null;
        state.isActiveSubscriber = true;
        state.selectedFile = null;
        state.cannedResponses = [];
        state.isTodoCompleted = false;
      })
      .addCase(setSelectedUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.isActiveSubscriber = action.payload.isActiveSubscriber;
        state.isTodoCompleted = checkTodoCompleted(
          action.payload.user.id,
          action.payload.user.todoCheckList
        );
      })
      .addCase(updateCoachingUsersChecklist.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          todoCheckList: action.payload,
        };
        state.isTodoCompleted = checkTodoCompleted(
          state.user.id,
          action.payload
        );
      })
      .addCase(addNewCannedResponse.fulfilled, (state, action) => {
        state.cannedResponses = action.payload;
      })
      .addCase(updateCannedResponse.fulfilled, (state, action) => {
        state.cannedResponses = action.payload;
      })
      .addCase(deleteCannedResponseItem.fulfilled, (state, action) => {
        state.cannedResponses = action.payload;
      })
      .addCase(cancelCoachingTrials.fulfilled, (state, action) => {
        const index = state.conversationList.findIndex(
          (conversation) => conversation.url !== action.conversationId
        );
        const newArray = [...state.conversationList];
        const { userDetails } = state.conversationList[index];
        userDetails.subscription = {
          ...userDetails.subscription,
          ...action.payload.subscription,
        };
        newArray[index].userDetails = userDetails;
        state.conversationList = newArray;
        state.user = {
          ...state.user,
          subscription: {
            ...state.user.subscription,
            ...action.payload.subscription,
          },
        };
      });
  },
});
/* eslint-disable no-param-reassign */

export const {
  setSelectedChat,
  setSelectedMessage,
  resetSelectedMessage,
  addFile,
  removeFile,
  setCannedResponses,
  setConversationList,
  updateConversationSubscription,
} = chatsSlice.actions;

export default chatsSlice.reducer;
