/**
 * @module config
 * @description Service for the application configuration
 * @copyright Aura Health, Inc.
 */

// Internal dependencies
const { name, version } = require('../package.json');
const appConstants = require('./utils/constants/app');

// Store the configuration
const config = {
  environment: process.env.NODE_ENV,
  mode: process.env.NEXT_PUBLIC_MODE,
  appDomain: process.env.NEXT_PUBLIC_APP_DOMAIN,
  firebase: {
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    vapidKey: process.env.NEXT_PUBLIC_FIREBASE_NOTIFICATION_VAP_ID,
  },
  grammarly: {
    clientId: process.env.NEXT_PUBLIC_GRAMMARLY_CLIENT_ID,
  },
  sendBird: {
    appId: process.env.NEXT_PUBLIC_SENDBIRD_APP_ID,
  },
  agora: {
    appId: process.env.NEXT_PUBLIC_AGORA_APP_ID,
  },
  service: {
    name,
    version,
  },
  logger: {
    level: process.env.NEXT_PUBLIC_LOGGER_LEVEL || 'info',
    useJSON: !(process.env.NEXT_PUBLIC_LOGGER_USE_JSON === 'false'),
  },
  bugsnag: {
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
    releaseStage: process.env.NEXT_PUBLIC_MODE,
    appVersion: version,
  },
  mixpanel: {
    token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
  },
  api: {
    auraServices: `${process.env.NEXT_PUBLIC_APP_DOMAIN}/auraServices`,
    auraApi: {
      url: `${process.env.NEXT_PUBLIC_APP_DOMAIN}/auraApi`,
      shield: process.env.NEXT_PUBLIC_AURA_API_SHIELD,
    },
  },
  userWeb: process.env.NEXT_PUBLIC_USER_WEB,
  onsched: {
    url: process.env.NEXT_PUBLIC_ONSCHED_URL,
  },
  googleOAuth: {
    clientId: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID,
  },
  outlookOAuth: {
    clientId: process.env.NEXT_PUBLIC_OUTLOOK_OAUTH_CLIENT_ID,
  },
  auraCoachingTestUser:
    process.env.NEXT_PUBLIC_MODE === appConstants.MODE_PRODUCTION
      ? 'O4Js8xPNytgoLRO9c6tvY6ScJQJ3'
      : 'EJpWhk2RJeTTu9Us8qPJUQiZGpU2',
};

module.exports = config;
