import axios from 'axios';
import { isAfter, addDays } from 'date-fns';
import Logger from '../services/Logger';
import FirebaseDatabase from '../services/FirebaseDatabase';
import { isValidEmail } from '../utils/validators';

import config from '../config';
import appConstants from '../utils/constants/app';
import Auth from '../services/FirebaseAuth';
import {
  notifyAPIError,
  notifyHandledError,
} from '../services/ErrorMonitoring';

async function generateUserId() {
  const res = await FirebaseDatabase.generateId(`users`);
  return res && res.key;
}

async function getUser(id) {
  let user = null;
  try {
    if (id) {
      Logger.debug(`users: get ${id}`);
      const value = await FirebaseDatabase.getValue(`/users/${id}`);
      if (value !== null) {
        user = {
          id,
          ...value,
        };
      }
    }
  } catch (error) {
    notifyHandledError(error, { message: 'Error fetching user', userId: id });

    return null;
  }

  return user;
}

async function getUserFromApi() {
  let user = null;
  try {
    const authToken = await Auth.getUserAuthToken();
    if (authToken) {
      Logger.debug(`users: get from api`);
      const options = {
        method: 'GET',
        url: `${config.api.auraServices}/users/me`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        json: true,
      };

      const response = await axios(options);
      if (response && response.status === 200 && response.data) {
        user = response.data;
      }
    }
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching user' });

    return null;
  }
  return user;
}

async function setUser(user, id) {
  const userData = user;
  if (userData.id) {
    delete userData.id;
  }
  let res = null;

  if (userData) {
    try {
      res = await FirebaseDatabase.setValue(`users/${id}`, userData);
    } catch (error) {
      notifyHandledError(error, { message: 'Error setting user', userId: id });
    }
  }
  return res;
}

async function setUserWithoutAuth(profile) {
  Logger.debug('user: create');
  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/users`,
      data: JSON.stringify(profile),
      headers: {
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.data) {
      return response.data;
    }
  } catch (error) {
    notifyAPIError(error, { message: 'Error creating user' });
    return { error };
  }
  return { error: 'Failed to create user' };
}

async function handleUpdateUser(user, id) {
  const userData = { ...user };

  if (!id) return;

  if (userData.id) {
    delete userData.id;
  }

  await FirebaseDatabase.updateValue(`users/${id}`, userData);
}

async function getUserFromEmail(email) {
  let user = null;
  try {
    if (email) {
      Logger.debug('users: getFromEmail');
      if (isValidEmail(email)) {
        const encodedEmail = Buffer.from(email).toString('base64');
        const userEmail = await FirebaseDatabase.getValue(
          `/emailUser/${encodedEmail}`
        );
        if (userEmail && userEmail.userId) {
          user = await getUser(userEmail.userId);
        }
      }
    } else {
      Logger.warn('No email specified');
    }
  } catch (error) {
    notifyHandledError(error, { message: 'Error fetching user by email' });
    return null;
  }
  return user;
}

async function getUserFromAuth(auth) {
  let user = null;
  try {
    if (auth) {
      Logger.debug('users: getFromAuth');
      const { uid, email, providerData } = auth;
      const emailId =
        email || (providerData && providerData[0])
          ? providerData[0].email
          : null;
      if (emailId) {
        user = await getUserFromEmail(emailId);
        if (!user) {
          if (uid) {
            user = await getUser(uid);
          }
        }
      } else if (uid) {
        user = await getUser(uid);
      }
    }
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching user from Auth',
      id: auth?.uid,
    });
    return null;
  }
  return user;
}

async function getUserFromReferralCode(referralCode) {
  if (!referralCode || referralCode === '') {
    return null;
  }
  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/referralCodes/getUser/${referralCode}`,
      headers: {
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return {
        ...response.data,
        referralCode,
      };
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching user by referral code' });
    return null;
  }
}

async function getUserReferrals() {
  try {
    const authToken = await Auth.getUserAuthToken();
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/referrals`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      Logger.debug('user referrals', { data: response.data });
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching user referrals' });
    return null;
  }
}

async function getUserReferralFriends(userId) {
  if (!userId || userId === '') {
    return null;
  }
  try {
    const value = await FirebaseDatabase.getValue(`/referralFriends/${userId}`);
    if (value !== null) {
      return value;
    }
  } catch (error) {
    Logger.error('Failed to get referral friends', { error });
  }
  return null;
}

async function getUserSubscription(userID) {
  if (!userID || userID === '') {
    return null;
  }
  let subscription = null;

  try {
    const value = await FirebaseDatabase.getValue(
      `/userSubscriptions/${userID}`
    );
    if (value !== null) {
      subscription = {
        ...value,
      };
    }
  } catch (error) {
    notifyHandledError(error, {
      message: 'Error fetching user subscriptions',
      userId: userID,
    });
  }
  return subscription;
}

async function getUserCoachingSubscription(userId, coachId) {
  if (!userId || userId === '' || !coachId || coachId === '') {
    return null;
  }

  try {
    const authToken = await Auth.getUserAuthToken();

    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/coaching/subscriptions/${userId}/${coachId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },

      json: true,
    };

    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error fetching user coaching subscriptions',
    });
    return null;
  }
}

function isCoachOnboardingCompleted({ coachDetails }) {
  let isCompleted = true;
  if (!coachDetails) {
    isCompleted = false;
  }

  const { name, gender, bio, professionalTitle, profilePicture, paypalEmail } =
    coachDetails || {};

  if (
    !name ||
    !gender ||
    !bio ||
    !professionalTitle ||
    !profilePicture ||
    !paypalEmail
  ) {
    isCompleted = false;
  }

  return isCompleted;
}

function createUserProfileFromAuth(authData, givenName, attributionData = {}) {
  const providerData =
    authData.providerData && authData.providerData[0]
      ? authData.providerData[0]
      : null;
  const email = authData.email || (providerData && providerData.email);
  const provider =
    providerData && providerData.providerId ? providerData.providerId : null;
  const profile = {
    provider,
    email,
    createdAt: new Date().toISOString(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  function findLastName(name) {
    const n = name.split(` `);
    if (n.length > 1) {
      return n[n.length - 1];
    }
    return null;
  }
  if (providerData) {
    if (providerData.displayName) {
      profile.name = providerData.displayName;
      const [displayName] = providerData.displayName.split(` `);
      profile.givenName = displayName;
      const lastName = findLastName(providerData.displayName);
      if (lastName) {
        profile.familyName = lastName;
      }
    }
    if (providerData.givenName) {
      // does not usually happen
      profile.givenName = providerData.givenName;
    }

    if (providerData.photoURL) {
      profile.profilePicture = providerData.photoURL;
    }
  }
  profile.isAnonymous = authData.isAnonymous;
  // handle saved name
  if (givenName && givenName !== ``) {
    profile.givenName = givenName.trim();
  }
  profile.signUpSource = appConstants.APP_NAME;

  profile.attributionData = {
    ...attributionData,
    feature: `coach_signup_web`,
    sourcePlatform: appConstants.APP_NAME,
  };

  return profile;
}

async function getBackgroundRemovedPhoto({ url, imageFileName }) {
  const authToken = await Auth.getUserAuthToken();
  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/contents/removeBackground`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data: {
        prefix: 'test',
        filename: imageFileName,
        url,
      },
      json: true,
    };

    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error getting background removed photo',
    });
    return null;
  }
}

function isUserPremium(user = {}) {
  return user.premium || user.premiumTrial;
}

function isUserCoachingSubscriber(user = {}, coachId) {
  if (!user || !coachId) return false;
  const { id, premiumCoaching, coaches, premiumCoach } = user;

  if (premiumCoaching && coaches && coaches[coachId]) return true;
  if (premiumCoach && coaches && coaches[coachId]) return true;
  if (id === config.auraCoachingTestUser) {
    const { subscription } = user;
    const { transactionId, deletedAt } = subscription || {};
    return !!transactionId && !deletedAt;
  }
  return false;
}

function hasUserCoachingAppointment(user = {}, coachId, appointmentsList = []) {
  if (!user || !coachId) return false;
  const userAppointments = appointmentsList.filter(
    (item) =>
      item.userId === user.id &&
      item.coachId === coachId &&
      item.includesCoaching &&
      isAfter(addDays(new Date(item.end), 7), new Date())
  );
  return userAppointments.length > 0;
}

function isUserCoachingSubscriptionActive(
  user = {},
  coachId,
  appointments = []
) {
  const { subscription } = user;
  const { cancelledAt } = subscription || {};
  if (
    (isUserCoachingSubscriber(user, coachId) && !cancelledAt) ||
    hasUserCoachingAppointment(user, coachId, appointments)
  )
    return true;

  return false;
}

function matchUserRole({ user, role }) {
  return user.role === role;
}

function getUserCoachingTargets({ coachingPreference }) {
  const targetValues = {
    sleep: 480,
    mindfulness: 30,
    mood: 3,
  };
  if (!coachingPreference) return targetValues;
  Object.keys(coachingPreference).forEach((key) => {
    const preference = coachingPreference[key];
    if (preference?.goal) {
      targetValues[key] = preference.goal;
    }
  });
  return targetValues;
}

async function getZommOauthUrl({ redirectUrl }) {
  const authToken = await Auth.getUserAuthToken();
  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/videoConferences/zoom/oauth/authorizationUrl?redirectUrl=${redirectUrl}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };

    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error getting Zoom oauth url',
    });
    return null;
  }
}

export {
  generateUserId,
  getUser,
  getUserFromApi,
  setUser,
  handleUpdateUser,
  setUserWithoutAuth,
  getUserFromAuth,
  getUserFromEmail,
  getUserFromReferralCode,
  getUserReferrals,
  getUserSubscription,
  getUserCoachingSubscription,
  isCoachOnboardingCompleted,
  isUserPremium,
  isUserCoachingSubscriber,
  isUserCoachingSubscriptionActive,
  hasUserCoachingAppointment,
  createUserProfileFromAuth,
  matchUserRole,
  getUserCoachingTargets,
  getBackgroundRemovedPhoto,
  getUserReferralFriends,
  getZommOauthUrl,
};
