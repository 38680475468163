const liveEventActivity = {
  COMMENT: 'comment',
  DONATE: 'donate',
  FOLLOW: 'follow',
  COACHING: 'coaching',
  LIKE: 'like',
  SHOW_COACHING: 'showCoaching',
  HIDE_COACHING: 'hideCoaching',
  SHOW_DONATION: 'showDonation',
  HIDE_DONATION: 'hideDonation',
  SHOW_NEXTSESSION: 'showNextSession',
  HIDE_NEXTSESSION: 'hideNextSession',
};

const calendarType = {
  GOOGLE: 'google',
  OUTLOOK: 'outlook',
};

const streamType = {
  LIVE: 'live',
  RTC: 'rtc',
};

const streamJoinStatusList = {
  INITIAL: 'initial',
  JOINING: 'joining',
  JOINED: 'joined',
};

const suggestions = {
  SHOW_DONATION: 'showDonation',
  SHOW_COACHING: 'showCoaching',
  SHOW_NEXTSESSION: 'showNextSession',
};

module.exports = {
  liveEventActivity,
  calendarType,
  streamType,
  streamJoinStatusList,
  suggestions,
};
