import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getScheduledAppointmentList } from '../../models/appointment';
import Logger from '../../services/Logger';
import { deleteUpcomingService } from '../../models/service';

export const getAppointmentList = createAsyncThunk(
  'appointment/getAppointmentList',
  async (coachId, { rejectWithValue }) => {
    try {
      const appointmentList = await getScheduledAppointmentList(coachId);
      return appointmentList;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const deleteAppointment = createAsyncThunk(
  'appointment/deleteAppointment',
  async (appointmentId, { rejectWithValue }) => {
    try {
      const response = await deleteUpcomingService(appointmentId);

      return response;
    } catch (error) {
      Logger.error('Error deleting appointment', { error });
      return rejectWithValue({ error });
    }
  }
);

const initialState = {
  list: null,
  isLoading: false,
  error: false,
};

/* eslint-disable no-param-reassign */
export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    updateAppointmentHasCoachFeedback: (state, action) => {
      const appointmentIndex = state.list.findIndex(
        (appointment) => appointment.id === action.payload
      );

      const newArray = [...state.list];
      newArray[appointmentIndex].hasCoachFeedback = true;

      state.list = newArray;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppointmentList.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.list = null;
      })

      .addCase(getAppointmentList.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(deleteAppointment.fulfilled, (state, action) => {
        state.list = state.list.filter(
          (item) => item?.id !== action.payload?.id
        );
      })

      .addCase(getAppointmentList.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});
/* eslint-disable no-param-reassign */

export const { updateAppointmentHasCoachFeedback } = appointmentSlice.actions;

export default appointmentSlice.reducer;
