import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  listUnapprovedFeatures,
  retrieveCoachFeature,
} from '../../models/features';
import Logger from '../../services/Logger';
import BugsnagClient from '../../services/Bugsnag';
import { notifyHandledError } from '../../services/ErrorMonitoring';

export const getFeatures = createAsyncThunk(
  'features/getFeatures',
  async ({ coachId, feature }) => {
    try {
      const response = await retrieveCoachFeature({
        coachId,
        feature,
      });

      return response;
    } catch (error) {
      Logger.error('Error fetching features', { error });
      BugsnagClient.notify(error);
      notifyHandledError(error, { message: 'Error fetching features' });
      return null;
    }
  }
);

export const getAllUnapprovedFeatures = createAsyncThunk(
  'features/getAllUnapprovedFeatures',
  async () => {
    Logger.debug('Fetching unapproved features');
    const result = await listUnapprovedFeatures();
    if (result) {
      Logger.debug('Fetched unapproved features');
      return result;
    }
    return null;
  }
);

function isPendingAction(action) {
  return (
    action.type.startsWith('features/') && action.type.endsWith('/pending')
  );
}

const initialState = {
  coachFeature: null,
  unapprovedFeatures: [],
  loading: false,
  isFetched: false,
};

/* eslint-disable no-param-reassign */
export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    deleteFeature: (state, action) => {
      const { coachId, feature } = action.payload;
      const updatedUnapprovedFeature = state.unapprovedFeatures.filter(
        (item) => item.coachId !== coachId && item.feature !== feature
      );
      state.unapprovedFeatures = updatedUnapprovedFeature;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeatures.fulfilled, (state, action) => {
        state.coachFeature = Object.keys(action.payload || {}).length
          ? action.payload
          : null;
        state.loading = false;
      })
      .addCase(getAllUnapprovedFeatures.fulfilled, (state, action) => {
        state.unapprovedFeatures = action.payload;
        state.loading = false;
        state.isFetched = true;
      })

      .addMatcher(isPendingAction, (state) => {
        state.loading = true;
      });
  },
});
/* eslint-disable no-param-reassign */

export const { fetchedCoachFeatures, deleteFeature } = featuresSlice.actions;

export default featuresSlice.reducer;
