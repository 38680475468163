import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { handleUpdateChannel } from '../../models/channel';

import { notifyHandledError } from '../../services/ErrorMonitoring';

const initialState = {
  list: {},
  analytics: {},
  loading: false,
  isFetched: false,
};

export const updateChannel = createAsyncThunk(
  'channels/updateChannel',
  async ({ channelId, updates }) => {
    try {
      const { data } = await handleUpdateChannel({
        id: channelId,
        data: updates,
      });
      return { channelId, updates: data };
    } catch (error) {
      notifyHandledError(error, {
        message: 'Error updating channel',
        channelId,
      });

      throw error;
    }
  }
);

/* eslint-disable no-param-reassign */
export const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    fetchChannels: (state) => {
      state.loading = true;
    },
    fetchedChannels: (state, action) => {
      state.list = action.payload;
      state.loading = false;
      state.isFetched = true;
    },
    fetchedChannel: (state, action) => {
      state.list[action.payload.channelId] = action.payload.channel;
    },
    fetchChannelsAnalytics: (state, action) => {
      state.analytics[action.payload.channelId] = {
        loading: true,
      };
    },
    fetchedChannelsAnalytics: (state, action) => {
      state.analytics[action.payload.channelId] = {
        loading: false,
        data: action.payload.analytics,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateChannel.fulfilled, (state, action) => {
      state.list[action.payload.channelId] = {
        ...state.list[action.payload.channelId],
        ...action.payload.updates,
      };
    });
  },
});
/* eslint-disable no-param-reassign */

export const {
  fetchChannels,
  fetchedChannels,
  fetchedChannel,
  fetchChannelsAnalytics,
  fetchedChannelsAnalytics,
} = channelsSlice.actions;

export default channelsSlice.reducer;
