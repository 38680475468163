import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
  getUserFromAuth,
  // getUser,
  setUserWithoutAuth,
  handleUpdateUser,
  getUserFromEmail,
  setUser,
  getUserFromApi,
} from '../../models/user';
import {
  createCoach,
  getCoach,
  setupCoachListener,
  updateCoach,
  updateFeatureEnabled,
  getCoachSchedulingDetails,
  updateCoachingFreeTrial,
  removeCoachListener,
} from '../../models/coach';

import Analytics from '../../services/Analytics';
import Logger from '../../services/Logger';
import BugsnagClient from '../../services/Bugsnag';
import Auth from '../../services/FirebaseAuth';

import appConstants, { ONBOARDING_TYPES } from '../../utils/constants/app';
import {
  notifyAPIError,
  notifyHandledError,
} from '../../services/ErrorMonitoring';

// --------------------- Thunk functions --------------------- //
export const handleCreateCoach = createAsyncThunk(
  'auth/createCoach',
  async (
    { userId, data, coachOnboardingType },
    { rejectWithValue, getState }
  ) => {
    try {
      const { locale = null } = getState().app || {};
      const response = await createCoach({ ...data, locale });
      if (response) {
        Analytics.identifyCoach({ ...data, id: userId });
        const createdCoach = response.data;
        if (
          coachOnboardingType === ONBOARDING_TYPES.CONTENT ||
          coachOnboardingType === ONBOARDING_TYPES.COACHING
        ) {
          for (let i = 1; i <= 3; i++) {
            const feature = ONBOARDING_TYPES.CONTENT ? 'content' : 'coaching';
            // eslint-disable-next-line no-await-in-loop
            await new Promise((resolve) => {
              setTimeout(resolve, 5000);
            });

            Logger.debug('Enabling feature', { coachOnboardingType });

            // eslint-disable-next-line no-await-in-loop
            const featureResponse = await updateFeatureEnabled({
              feature,
              coachId: userId,
            });
            if (featureResponse) {
              if (coachOnboardingType === ONBOARDING_TYPES.COACHING)
                // eslint-disable-next-line no-await-in-loop
                await updateCoachingFreeTrial(userId, true);
              Logger.info('Enabled  feature', { coachOnboardingType });
              break;
            }
            if (i === 3) {
              Logger.error('Unable to enable feature');
              BugsnagClient.notify(
                new Error('Error enabling feature for coach')
              );
            } else {
              notifyHandledError(new Error('Unable to enable feature'), {
                featureResponse,
                userId,
                coachOnboardingType,
              });
            }
          }
        }

        return createdCoach;
      }
      return null;
    } catch (error) {
      notifyAPIError(error, { message: 'Error creating coach', userId });
      return rejectWithValue({ error });
    }
  }
);

export const handleUpdateCoach = createAsyncThunk(
  'auth/updateCoach',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await updateCoach({ id, data });
      return response.data;
    } catch (error) {
      notifyAPIError(error, {
        message: 'Error updating coach',
        userId: id,
      });
      return rejectWithValue({ error });
    }
  }
);

export const handleSetUserWithoutAuth = createAsyncThunk(
  'auth/getUserWithoutAuth',
  async (profile) => {
    const user = await setUserWithoutAuth(profile);
    return user;
  }
);

export const updateUserProfile = createAsyncThunk(
  'auth/updateUserProfile',
  async ({ profile, id, saveToDatabase }, { rejectWithValue }) => {
    if (saveToDatabase) {
      try {
        await handleUpdateUser(profile, id);
      } catch (error) {
        notifyHandledError(error, { message: 'Error updating user' });
        rejectWithValue({ error });
      }
    }
    return profile;
  }
);

export const handleGetUserFromEmail = createAsyncThunk(
  'auth/getUserFromEmail',
  async (email) => {
    const user = await getUserFromEmail(email);
    return user;
  }
);

export const setCoachSchedulingDetails = createAsyncThunk(
  'auth/setCoachSchedulingDetails',
  async (id) => {
    const schedulingResponse = await getCoachSchedulingDetails(id);
    return schedulingResponse;
  }
);

const fetchedCoach = createAction('auth/fetchedCoach');

export const getUserWithAuth = createAsyncThunk(
  'auth/getUserWithAuth',
  async (auth, { dispatch }) => {
    // Reset user state only if existing user does not exist
    let user = await getUserFromAuth(auth);
    if (!user) {
      user = await getUserFromApi();
      if (user) {
        BugsnagClient.setUser(auth.uid, auth.email);
        notifyHandledError(
          new Error('User not fetched from Firebase, fetched from API'),
          {
            message: 'User not fetched from Firebase, fetched from API',
            ...auth,
          }
        );
      }
    }
    if (user) {
      const coach = await getCoach(user.id);
      if (coach) {
        await dispatch(setCoachSchedulingDetails(user.id));
      }
      dispatch(fetchedCoach(coach));
      Analytics.identifyCoach(coach);
      // Listen to Firebase continuously for coach details
      setupCoachListener(user.id, (coachDetails) => {
        Analytics.identifyCoach(coachDetails);
        dispatch(fetchedCoach(coachDetails));
      });
      return user;
    }
    return null;
  }
);

export const handleSetUser = createAsyncThunk(
  'auth/setUser',
  async ({ profile, id }, { getState }) => {
    const { locale = null } = getState().app || {};
    const error = await setUser({ locale, ...profile }, id);
    let user = null;

    if (!error) {
      user = { ...profile, id };

      const { givenName, email, provider } = profile;

      Analytics.identifyUser({ givenName, email, id }, true);

      Analytics.setPeopleProperties({
        UserID: id,
        $first_name: givenName,
        $created: new Date(),
        $email: email,
        Provider: provider,
        'Attribution Source Platform': appConstants.APP_NAME,
      });

      Analytics.setSuperProperties({
        UserID: id,
        Name: givenName,
        Provider: provider,
        'Join date': new Date().toISOString(),
        'Attribution Source Platform': appConstants.APP_NAME,
      });

      if (locale) {
        Analytics.setPeopleProperties({ Locale: locale });
        Analytics.setSuperProperties({ Locale: locale });
      }

      Analytics.track(`Sign up login`, {
        Time: new Date().toTimeString().slice(0, 2),
        Day: new Date().getDay(),
        Provider: provider,
        UserEmail: email,
      });

      return user;
    }
    return null;
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await Auth.signOut();
  Analytics.resetUser();
  removeCoachListener();
  return true;
});
// --------------------- Thunk functions --------------------- //

function isRejectedAction(action) {
  return action.type.startsWith('auth/') && action.type.endsWith('/rejected');
}

const initialState = {
  authLoading: true, // Loader for authListener - this is set to false when Firebase auth listener fires
  isLoading: false,
  user: null,
  error: null,
  coachDetails: null,
  coachSchedulingDetails: null,
};

/* eslint-disable no-param-reassign */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthLoading: (state, action) => {
      state.authLoading = action.payload;
    },
    fetchedCoachSchedulingDetails: (state, action) => {
      state.coachSchedulingDetails = action.payload;
    },
    updateCoachSchedulingDetails: (state, action) => {
      state.coachSchedulingDetails = {
        ...state.coachSchedulingDetails,
        ...action.payload,
      };
    },
    updatedCoach: (state, action) => {
      state.coachDetails = {
        ...state.coachDetails,
        ...action.payload,
      };
    },
    fetchedCoach: (state, action) => {
      state.coachDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.pending, (state, action) => {
        const { preventUserLoading } = action.meta.arg;
        if (!preventUserLoading) {
          state.isLoading = true;
          state.error = null;
        }
      })
      .addCase(handleGetUserFromEmail.pending, (state, action) => {
        const email = action.meta.arg;
        const existingUser = state.user;
        if (!existingUser || existingUser.email !== email) {
          state.isLoading = true;
          state.error = null;
        }
      })
      .addCase(getUserWithAuth.pending, (state) => {
        if (!state.user) {
          state.isLoading = true;
          state.error = null;
        }
      })
      .addCase(handleSetUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })

      .addCase(handleCreateCoach.fulfilled, (state, action) => {
        state.coachDetails = action.payload;
      })
      .addCase(handleUpdateCoach.fulfilled, (state, action) => {
        if (state.user.id === action.payload.id) {
          state.coachDetails = { ...state.coachDetails, ...action.payload };
        }
      })
      .addCase(setCoachSchedulingDetails.fulfilled, (state, action) => {
        state.coachSchedulingDetails = action.payload;
      })
      .addCase(handleSetUserWithoutAuth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = {
          ...state.user,
          ...action.payload,
        };
      })
      .addCase(handleGetUserFromEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(getUserWithAuth.fulfilled, (state, action) => {
        const user = action.payload;
        if (user) {
          state.isLoading = false;
          state.user = user;
          state.error = null;
        } else {
          state.isLoading = false;
          state.user = null;
          state.error = true;
        }
      })
      .addCase(handleSetUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.user = null;
      })

      .addMatcher(isRejectedAction, (state, action) => {
        state.isLoading = false;
        state.error = true;
        if (action.type === 'auth/getUserFromEmail') {
          state.user = action.payload;
        }
      });
  },
});
/* eslint-disable no-param-reassign */

export const {
  setAuthLoading,
  fetchUser,
  fetchedCoachSchedulingDetails,
  updateCoachSchedulingDetails,
  updatedCoach,
} = authSlice.actions;

export default authSlice.reducer;
