import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateTemplate } from '../../models/homeworkSchedule';
import { notifyHandledError } from '../../services/ErrorMonitoring';
import { resetSelectedChat } from './chats';

export const updateScheduleName = createAsyncThunk(
  'homeworkSchedule/updateScheduleName',
  async (name, { getState, rejectWithValue }) => {
    try {
      const { schedule } = getState().homeworkSchedule;
      const updatedTemplate = await updateTemplate({
        id: schedule.id,
        data: { ...schedule, name },
      });
      return updatedTemplate;
    } catch (error) {
      notifyHandledError(error, { message: 'Error updating schedule name' });
      return rejectWithValue({ error });
    }
  }
);

const initialState = {
  schedule: [],
  type: 'template',
  loading: false,
  isFetched: false,
};

/* eslint-disable no-param-reassign */
export const homeworkScheduleSlice = createSlice({
  name: 'homeworkSchedule',
  initialState,
  reducers: {
    fetchSchedule: (state) => {
      state.loading = true;
      state.isFetched = false;
    },
    fetchedSchedule: (state, action) => {
      state.schedule = action.payload.schedule;
      state.type = action.payload.type || 'template';
      state.loading = false;
      state.isFetched = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateScheduleName.fulfilled, (state, action) => {
        state.schedule = action.payload;
      })
      .addCase(resetSelectedChat, (state) => {
        state.schedule = [];
        state.type = 'template';
        state.loading = false;
        state.isFetched = false;
      });
  },
});
/* eslint-disable no-param-reassign */

export const { fetchSchedule, fetchedSchedule } = homeworkScheduleSlice.actions;

export default homeworkScheduleSlice.reducer;
